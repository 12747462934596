import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import NotVerifiedMobilenumber from './NotKnownMobilenumber';
import NotVerifiedEmail from './NotKnownEmail';
import VerifiedNominee from './VerifiedNominee';
import VerifiedAuthorisedPerson from './verifiedAuthorisedPerson';
import Separator from '../../../common/ui/Separator';
import ProfileUploader from './Profileuploader';
import VerifiedText from './VerifiedText';
import ImagePickerModal from '../../../common/ui/Modal/ImagePickerModal';
import CustomButton from '../../../common/ui/CustomButton';
import { convertDateToServerFormat } from '../../../common/utils';
import {
	familyUploadLoading,
	familyUploadRequest,
} from '../ImageUpload/Upload.Slice';
import { ReactComponent as warningRed } from '../../../common/icons/warningRed.svg.svg';
import SvgConverter from '../../../common/SvgConverter';

const FamilyDetailCard = ({
	profileData,
	addNewMobile,
	addNewEmail,
	addNewAddress,
	onVerifyEmail,
	onVerifyMobile,
	profile,
	addressData,
	selectAddressSeqId,
	editMember,
	selectRelationship
}: {
	profileData?: any;
	addNewMobile?: () => void;
	addNewEmail?: () => void;
	addNewAddress?: () => void;
	onVerifyMobile?: () => void;
	onVerifyEmail?: () => void;
	profile: any;
	addressData: any;
	selectAddressSeqId: any;
	editMember?: () => void;
	selectRelationship?: () => void;
}) => {
	const theme = useTheme();
	const isImageLoading = useSelector(familyUploadLoading);
	const [selectedSeqId, setSelectedSeqId] = useState(null);

	const convertedMobile = profileData?.mobNoVerified?.toUpperCase() ?? '';
	const convertedEmail = profileData?.emailVerified?.toUpperCase() ?? '';
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);
	const matchId = selectAddressSeqId === profile?.seqId;

	const { t } = useTranslation();

	const [selectedData, setSelectedData] = useState<any>({});
	// const [uploadImageBase64, setUploadImageBase64] = React.useState<
	// 	string | null
	// >(null);

	const isEditable = profileData.relationCode.length > 0

	const handleUpload = () => {
		setOpen(true);

		setSelectedData({
			seqId: profile.seqId,
			dob: profile.dob,
			relationCode: profile.relationCode,
			flag: profile.flag,
		});
		setSelectedSeqId(profile.seqId);
	};
	// useEffect(() => {
	// 	setSelectedSeqId(null);
	// }, [profile]);
	const processImage = (data: any, file: any) => {
		try {
			const seqId = selectedData?.seqId;
			const dob = convertDateToServerFormat(selectedData?.dob);
			const relationCode = selectedData?.relationCode;
			const imageBase64 = data;
			const fileName = file?.target?.files?.[0]?.name;
			// setUploadImageBase64(imageBase64 as string);
			const flag = selectedData?.flag;

			const generateUniqueFileName = () => {
				const timestamp = Date.now();
				const randomId = Math.random().toString(36).substring(2, 8);
				return `image_${timestamp}_${randomId}.jpg`;
			};
			const fileNames = generateUniqueFileName();
			dispatch(
				familyUploadRequest({
					seqId,
					dob,
					relationCode,
					imageBase64,
					fileName: fileName || fileNames,
					flag,
				} as any)
			);
		} catch (error) {
			// Empty catch block
		}
	};

	// eslint-disable-next-line no-nested-ternary
	const displayData = (profile.address1 || profile.address2 || profile.address3 || profile.city || profile.state || profile.pin) ? profile : matchId ? addressData : '';

	useEffect(() => {
		setSelectedSeqId(null);
	}, [profile]);

	return (
		<Grid container flexDirection="column" className="mb-30">
			<div className="container-family">
				{!isEditable && <Grid item container justifyContent={'space-between'}>


					<Grid container item
						display="flex"
						xs={9}
						alignItems="base">
						<SvgConverter Icon={warningRed} />
						<Typography className="f-16 fw-500 pl-5" color="error">
							{t('RELATIONSHIP_UNKNOWN')}
						</Typography>

					</Grid>
					<CustomButton
						text={t('TAP TO SELECT RELATIONSHIP')}
						variant="text"
						color="primary"
						showIcon={false}
						className="f-14 fw-600 mt-5"
						onClick={selectRelationship}
					// onClick={() => { }}
					/>

				</Grid>}
				<Grid item container justifyContent={'space-between'}>
					<Typography className="f-16 fw-700 mb-4" color="primary">
						{profileData.relationName}
					</Typography>
					{isEditable &&

						<CustomButton
							text={t('EDIT/REMOVE')}
							variant="text"
							color="primary"
							showIcon={false}
							className="f-14 fw-400"
							onClick={editMember}

						/>
					}

				</Grid>
				{/* <Grid item>
					
				</Grid> */}
				<Grid item xs={12} container spacing={2} minHeight="110px">
					<Grid item xs={3}>
						<ProfileUploader
							handleUpload={isEditable ? editMember : selectRelationship}
							profile={profile}
							// uploadImageBase64={uploadImageBase64}
							uploadImageBase64={null}
							isImageLoading={isImageLoading}
							selectedSeqId={selectedSeqId}
							relationshipType={profileData.relationName}
							imageBase64={true}

						/>
						<ImagePickerModal
							open={open}
							setOpen={setOpen}
							handleImage={processImage}
							enableCropping
						/>
					</Grid>
					<Grid item xs={9} justifyContent="flex-start"  >
						<Grid item >
							<Typography className="f-18" color={theme.palette.common.black} style={{ wordWrap: 'break-word' }}>
								{profileData.firstName} {profileData.middleName}{' '}
								{profileData.lastName}
							</Typography>
						</Grid>
						<Grid item>
							<Typography className="f-14" color="primary">
								[ {t('EID_ID')}:&nbsp;
								{profileData.eiaNoFamily ? (
									profileData.eiaNoFamily
								) : (
									<Typography
										component="span"
										className="f-14"
										color={theme.palette.error.main}
									>
										{t('NOT_KNOWN')}
									</Typography>
								)}
								]
							</Typography>
						</Grid>
						<Grid item>
							{profileData.authorizedPerson === 'Y' && (
								<VerifiedAuthorisedPerson />
							)}
						</Grid>
						<Grid item>
							{(profileData.nomineeFlag === 'Y' || profileData.rnomineeFlag === 'Y') && <VerifiedNominee />}
						</Grid>

					</Grid>
				</Grid>

				<Grid item className="mt-5" minHeight="75px">
					<Grid container spacing={0.4}>
						<Grid item>
							<Typography
								className="f-14 fw-600"
								color={theme.palette.primary.main}
							>
								{t('MOBILE_NUMBER')}
							</Typography>
						</Grid>
						{profileData.mobNumber && (
							<Grid item className="mt-2">
								<VerifiedText
									isVerified={convertedMobile === 'Y'}
									label={
										convertedMobile === 'Y'
											? `[${t('VERIFIED')}]`
											: `[${t('NOT_VERIFIED')}]`
									}
								/>
							</Grid>
						)}
						{!profileData.mobNumber && <NotVerifiedMobilenumber />}

						{profileData.mobNumber && convertedMobile !== 'Y' && (
							<>
								<Grid item py={0.8}>
									<Separator orientation="vertical" className="mt-3" />
								</Grid>
								<Grid item flexDirection="row">
									<CustomButton
										text={t('TAP_HERE_TO_VERIFY')}
										variant="text"
										showIcon={false}
										color="primary"
										className="f-14  mt-n2 fw-400"
										onClick={onVerifyMobile}
									/>
								</Grid>
							</>
						)}
					</Grid>
					<Grid>
						{profileData.mobNumber && (
							<Grid item className="mb-4">
								<Typography className="f-18" color={theme.palette.common.black}>
									{profileData.mobNumber}
								</Typography>
							</Grid>
						)}
						{!profileData.mobNumber && (
							<Grid item>
								<Grid container spacing={1} flexWrap="nowrap">
									<Grid item>
										<Typography className="f-18">
											{t('NOT_AVAILABLE')}
										</Typography>
									</Grid>
									<Grid item flexDirection="row" alignItems="center">
										<CustomButton
											text={t('TAP_HERE_TO_ADD')}
											variant="text"
											showIcon={false}
											color="primary"
											onClick={addNewMobile}
											className="fw-400"
										/>
									</Grid>
								</Grid>
							</Grid>
						)}
					</Grid>

					<></>
				</Grid>
				<Grid item className="mt-5" minHeight="85px">
					<Grid container spacing={0.4}>
						<Grid item>
							<Typography
								className="f-14 fw-600"
								color={theme.palette.primary.main}
							>
								{t('EMAIL')}
							</Typography>
						</Grid>
						{profileData.email && (
							<Grid item className="mt-2">
								<VerifiedText
									isVerified={convertedEmail === 'Y'}
									label={
										convertedEmail === 'Y'
											? `[${t('VERIFIED')}]`
											: `[${t('NOT_VERIFIED')}]`
									}
								/>
							</Grid>
						)}
						{!profileData.email && <NotVerifiedEmail />}

						{profileData.email && convertedEmail !== 'Y' && (
							<>
								<Grid item py={0.8}>
									<Separator orientation="vertical" className="mt-2" />
								</Grid>
								<Grid item flexDirection="row" className="mt-n2">
									<CustomButton
										text={t('TAP_HERE_TO_VERIFY')}
										variant="text"
										showIcon={false}
										color="primary"
										className="f-14 mt-n2 fw-400"
										onClick={onVerifyEmail}
									/>
								</Grid>
							</>
						)}
					</Grid>
					<Grid>
						{profileData.email && (
							<Grid item className="mb-4">
								<Typography
									className="f-18 email-text"
									color={theme.palette.common.black}
								>
									{profileData.email}
								</Typography>
							</Grid>
						)}
						{!profileData.email && (
							<Grid item>
								<Grid container spacing={1} flexWrap="nowrap">
									<Grid item>
										<Typography className="f-18">
											{t('NOT_AVAILABLE')}
										</Typography>
									</Grid>
									<Grid item flexDirection="row">
										<CustomButton
											text={t('TAP_HERE_TO_ADD')}
											variant="text"
											showIcon={false}
											color="primary"
											onClick={addNewEmail}
											className="fw-400"
										/>
									</Grid>
								</Grid>
							</Grid>
						)}
					</Grid>

					<></>
				</Grid>
				<Grid item className="mt-5">
					<Grid container spacing={0.3} flexWrap="nowrap">
						<Grid item>
							<Typography
								className="f-14 fw-600"
								color={theme.palette.primary.main}
							>
								{t('ADDRESS')}
							</Typography>
						</Grid>
						{/* {profileData.address1 && (
							<Grid item className="mt-2">
								<VerifiedText
									isVerified={convertedAddress === 'Y'}
									label={convertedAddress === 'Y' ? 'verified' : 'not verified'}
								/>
							</Grid>
						)} */}
						{/* {!profileData.address1 && <AddressNotKnown />} */}

						{/* {profileData.address1 && !convertedAddress && (
							<>
								<Grid item>
									<Separator orientation="vertical" />
								</Grid>
								<Grid item flexDirection="row">
									<Typography className="f-14 " color="primary">
										<a href="#">TAP HERE TO VERIFY </a>
									</Typography>
								</Grid>
							</>
						)} */}
					</Grid>
					<Grid>
						{(displayData.address1 || displayData.address2 || displayData.address3 || displayData.city || displayData.state || displayData.pin) ? (
							<Grid item>
								<Typography className="f-18" color="textPrimary" style={{ wordWrap: 'break-word' }}>
									{displayData.address1}
								</Typography>
								<Typography className="f-18" color="textPrimary" style={{ wordWrap: 'break-word' }}>
									{displayData.address2}
								</Typography>
								<Typography className="f-18" color="textPrimary" style={{ wordWrap: 'break-word' }}>
									{displayData.city}
								</Typography>
								<Typography className="f-18" color="textPrimary" style={{ wordWrap: 'break-word' }}>
									{displayData.address3}
								</Typography>
								<Typography className="f-18" color="textPrimary" style={{ wordWrap: 'break-word' }}>
									{displayData.state}
								</Typography>
								{/* <Typography className="f-18" color="textPrimary" style={{ wordWrap: 'break-word' }}>
									{displayData.country}
								</Typography> */}
								<Typography className="f-18" color="textPrimary" style={{ wordWrap: 'break-word' }}>
									{displayData.pin}
								</Typography>
							</Grid>
						) : (
							<Grid item>
								<Grid container spacing={1} flexWrap="nowrap">
									<Grid item>
										<Typography className="f-18">
											{t('NOT_AVAILABLE')}
										</Typography>
									</Grid>
									<Grid item flexDirection="row" alignItems="center">
										<CustomButton
											text={t('TAP_HERE_TO_ADD')}
											variant="text"
											color="primary"
											showIcon={false}
											className="f-14"
											// onClick={() => handleNavigate()}
											onClick={addNewAddress}
										/>
									</Grid>
								</Grid>
							</Grid>
						)}
					</Grid>
				</Grid>
			</div>
		</Grid>
	);
};

export default FamilyDetailCard;
