import { Grid, Typography } from "@mui/material"
import CommonModal from "../../../common/ui/Modal/CommonModal"
import { useSelector } from "react-redux"
import { selectProofList } from "../Nominee/Nominee.slice"

export const OVDModal = ({ open, setOpen, selectDoc }: any) => {

    const proofList = useSelector(selectProofList);

    return (<CommonModal
        onClose={() => setOpen(false)}
        open={open}
        modalClassName="px-20 py-20"
        boxProps={{ width: 400, height: 500 }}
    ><Grid container>
            <Typography color="primary" className="fw-600 f-20 pl-20">
                Verification Documents
            </Typography>
        </Grid>
        <Grid container className="my-25 custom-scroll-bar" height="85%" >
            {proofList.map((item: any) => (<Grid container onClick={() => selectDoc(item?.nomineeProofCode)} >
                <Typography color="grey" className="fw-400 f-16 pb-10 cursor-pointer">
                    {item?.nomineeProofDesc}
                </Typography></Grid>))}
        </Grid>
    </CommonModal>)
}