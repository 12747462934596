import React, { useEffect, useMemo } from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CustomRadioGroup from '../../../../common/ui/Forms/FormInput/RadioButton/RadioGroup';
import CommonModal from '../../../../common/ui/Modal/CommonModal';
import CustomButton from '../../../../common/ui/CustomButton';
import Separator from '../../../../common/ui/Separator';
import { ReactComponent as AddMobileIcon } from '../../../../common/icons/update-mobile/Add_Mobile.svg';
import { ReactComponent as Cancel } from '../../../../common/icons/update-mobile/cancel_orange.svg';
import { ReactComponent as Verify } from '../../../../common/icons/update-mobile/verify_orange.svg';
import {
	getEmailList,
	removeEmail,
	selectPoliciesForEmails,
} from '../Emails.slice';
import { setAlertInfo } from '../../Common/Common.slice';

const RemoveEmailModal = ({
	open,
	setOpen,
	selectedEmail,
	toggleAddNumberModal,
	primaryFlag,
}: any) => {
	const theme = useTheme();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [selectedOption, setSelectedOption] = React.useState<any>(false);

	const { emailBasedPolicies, unAssignedEmails } = useSelector(
		selectPoliciesForEmails
	);

	useEffect(() => {
		if (!open) setSelectedOption(false);
	}, [open]);

	const emails = useMemo(
		() =>
			getEmailList(
				{ email: selectedEmail },
				emailBasedPolicies,
				unAssignedEmails
			).map((val: any) => ({
				value: val,
				label: val,
			})),
		[emailBasedPolicies, unAssignedEmails, selectedEmail]
	);

	const handleSubmit = () => {
		if (selectedOption) {
			dispatch(
				removeEmail({
					newValue: selectedOption,
					oldValue: selectedEmail,
				})
			);
			setOpen(false);
		} else {
			dispatch(
				setAlertInfo({
					open: true,
					type: 'FAILED',
					description: 'Please select an Email',
				})
			);
		}
	};

	const handleOptionChange = (value: string) => {
		setSelectedOption(value);
	};

	return (
		<CommonModal
			onClose={() => setOpen(true)}
			open={open}
			modalClassName="px-20 py-20"
			boxProps={{ width: 354 }}
		>
			<Grid item>
				<Grid item>
					<Typography
						className="f-16 fw-600"
						color={theme.palette.primary.main}
					>
						{String(primaryFlag).toUpperCase() === 'Y'
							? t('SELECT_THE_EMAILID_AS_PRIMARY')
							: t('REMOVE_EMAIL_SELECT_EMAIL_TO_ASSIGN_POLICIES')}
					</Typography>

					<Grid container>
						<Grid item xs={12} className="my-25 custom-scroll-bar" height="40vh">
							<CustomRadioGroup
								name="RadioGroup"
								options={emails}
								value={selectedOption}
								onChange={handleOptionChange}
								size="small"
							/>
						</Grid>
						<Grid item xs={12} className="py-2">
							<Typography
								className="f-16 fw-600"
								color={theme.palette.primary.main}
							>
								{t('OR')}
							</Typography>
						</Grid>
					</Grid>
					<Grid item className="py-10">
						<CustomButton
							text={t('ADD_A_EMAIL')}
							variant="text"
							color="primary"
							showIcon={false}
							onClick={() => toggleAddNumberModal(true, false)}
							fontSize={14}
							startIcon={AddMobileIcon}
						/>
					</Grid>

					<Grid item className="py-5">
						<Separator color={theme.palette.primary.main} borderWidth={1} />
					</Grid>
					<Grid container textAlign="center">
						<Grid item xs={12} className="py-10 ">
							<CustomButton
								text={t('PROCEED')}
								variant="text"
								color="primary"
								showIcon={false}
								onClick={handleSubmit}
								fontSize={16}
								fontWeight={600}
								startIcon={Verify}
							/>
						</Grid>
						<Grid item xs={12} className="py-10">
							<CustomButton
								text={t('CANCEL')}
								variant="text"
								color="primary"
								showIcon={false}
								startIcon={Cancel}
								onClick={() => setOpen(false)}
								fontSize={16}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</CommonModal>
	);
};

export default RemoveEmailModal;
