import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { t } from "i18next";
import SvgConverter from "../../../../common/SvgConverter";
import { APP_ROUTES } from "../../../../common/constants/Routes";
import theme from "../../../../common/theme";
import CustomButton from "../../../../common/ui/CustomButton";
import Spinner from "../../../../common/ui/Spinner";
import CustomCheckbox from "../../LoanModule/CustomCheckbox";
import { ReactComponent as AlertIcon } from '../../../../common/icons/ekyc_detailsone_alert_icon.svg';
import { useDispatch, useSelector } from "react-redux";
import { selectIsUserLoggedIn } from "../../../Auth/auth.slice";
import { selectAddressMismatch } from "../Dashboard.slice";
import { ReactComponent as Add } from "../../../../common/icons/plus-icon.svg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AddressMismatchModal } from "./AddressMismatchModal";
import cryptoEncryptionDecryption from "../../../../common/crypto";
import { getAddressEkycUrlRequest, selectGetAddressEkycUrlIsLoading } from "../../AddressModule/Address.slice";

export const AddressMismatchMerge = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [select, setSelect] = useState<string>();
    const [open, setOpen] = useState(false);

    const userData: any = useSelector(selectIsUserLoggedIn);
    const addressMismatch: any = useSelector(selectAddressMismatch);
    const ekycLoading = useSelector(selectGetAddressEkycUrlIsLoading);

    useEffect(() => {
        !addressMismatch?.length && navigate(APP_ROUTES.DASHBOARD)
    }, [])


    const AddNew = () => {
        const handleSuccess = (data: any) => {
            localStorage.setItem('ekcy', 'ekcy');
            const customerName = data?.customerName ? cryptoEncryptionDecryption.Encrypt(data?.customerName) : '';
            !customerName.length ? window.sessionStorage.setItem(
                'customerName', customerName
            ) : "";
            window.location.href = data?.url;
        };

        dispatch(getAddressEkycUrlRequest({ handleSuccess, flag: "addressMismatch" } as any))
    }


    const submit = () => {
        setOpen(true)
    }
    return (<Grid container justifyContent="center">
        <Box
            marginBottom={5}
            width={414}
            display="flex"
            justifyContent="end"
            flexDirection="column"
        >
            <Grid className="mb-10">
                <Card sx={{ minWidth: 100 }} variant="outlined">
                    <CardContent>
                        <Grid display="flex">
                            <Typography
                                sx={{ fontSize: 16 }}
                                color="black"
                                gutterBottom
                                className="ml-10 mt-1 fw-600"
                            >
                                {t('please select the correct permanent address to update')}
                            </Typography>
                        </Grid>
                        <Grid
                            display="flex"
                            color="secondary"
                            className="align-middle mt-15"
                        >
                            <Grid>
                                <SvgConverter Icon={AlertIcon} />
                            </Grid>
                            <Grid>
                                <Typography
                                    color="secondary"
                                    className="ml-10 fw-600 f-12 "

                                // className="ml-10 fw-600 f-12 "
                                >
                                    {t('Please select or provide your correct permanent address.').toUpperCase()}
                                </Typography>

                                <Typography color="secondary" className="ml-10 fw-600 f-12 ">
                                    {t('Providing the wrong address may lead to miscommunication.').toUpperCase()}
                                </Typography>
                            </Grid>
                        </Grid>

                        {false ? (
                            <Grid container justifyContent={'center'}>
                                <Spinner />
                            </Grid>
                        ) : (
                            <>
                                <Grid item container xs={12} className="px-1 py-5 mt-20">
                                    <Grid item xs={6}>
                                        <Typography
                                            color={theme.palette.primary.main}
                                            fontSize={12}
                                            fontWeight={400}
                                        // className="text-wrap"
                                        >
                                            [{t('eIA address')}]
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography
                                            color={theme.palette.error.main}
                                            fontSize={12}
                                            fontWeight={400}
                                        >
                                            [{t('Policy address')}]
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} className=" py-5 mt-10" alignItems={'initial'}>
                                    <Grid container xs={6} >
                                        <Grid item xs={1}>
                                            <CustomCheckbox
                                                mx={0}
                                                checked={select === 'permanentAddress'}
                                                onChange={() => {
                                                    setSelect("permanentAddress")
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={11}>
                                            <Typography
                                                className="f-16 fw-400 px-5"
                                                style={{ wordWrap: 'break-word' }}
                                            >
                                                &nbsp;
                                                {userData?.PermanentAddress?.fullAddress}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container xs={6} >
                                        <Grid item xs={1}>
                                            <CustomCheckbox
                                                mx={0}
                                                checked={select === 'policyAddress'}
                                                onChange={() => {
                                                    setSelect("policyAddress")
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={11}>
                                            <Typography
                                                className="f-16 fw-400 px-5"
                                                style={{ wordWrap: 'break-word' }}
                                            >
                                                &nbsp;{addressMismatch?.[0]?.fullAddress}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid container justifyContent={'center'} className="mt-20">
                                    <Typography className="fw-600 f-16 mt-20">
                                        {"OR"}
                                    </Typography>
                                </Grid>

                                <Grid container justifyContent={'center'} className="mt-10">
                                    <Typography className="fw-600 f-16 mt-5">
                                        {"Please add new permanent address"}
                                    </Typography>
                                </Grid>

                                <Grid container justifyContent={'end'} className="mt-5">
                                    <CustomButton
                                        text={t('Add New')}
                                        variant="text"
                                        color="primary"
                                        startIcon={Add}
                                        showIcon={false}
                                        className="fw-600 f-16 "
                                        onClick={AddNew}
                                        loading={ekycLoading}

                                    />
                                </Grid>

                                <Grid container xs={12} justifyContent={'space-between'} className="mt-20">
                                    <CustomButton
                                        text={t('CANCEL')}
                                        variant="text"
                                        color="inherit"
                                        showIcon={false}
                                        className="fw-600 f-16 "
                                        onClick={() => navigate(-1)}

                                    />
                                    <CustomButton
                                        text={t('PROCEED')}
                                        variant="text"
                                        color="primary"
                                        className="fw-600 f-16 "
                                        textAlign="end"
                                        onClick={submit}
                                        disabled={!(select?.length)}
                                    />
                                </Grid>

                            </>
                        )}
                    </CardContent>
                </Card>
            </Grid>
        </Box>
        <AddressMismatchModal open={open} setOpen={setOpen} select={select} />
    </Grid>)
}