import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import './Nominee.scss';
import { useDispatch, useSelector } from 'react-redux';
import NomineeHeader from './NomineeComponents/NomineeHeader';
import {
	fetchFamilyNameListAction,
	fetchNomineeList,
	selectNomineeIsLoading,
	selectNomineeList,
} from './Nominee.slice';
import { fetchFamilyRelationShipList } from '../Profile/Profile.slice';
import NomineeCard from './NomineeCard';
import Spinner from '../../../common/ui/Spinner';
import ApiModal from '../../../common/ui/Modal/ApiModal';

const NomineeScreen = () => {
	const dispatch = useDispatch();
	const isLoading = useSelector(selectNomineeIsLoading);
	const NomineesList: any = useSelector(selectNomineeList);

	useEffect(() => {
		dispatch(fetchFamilyRelationShipList());
		dispatch(fetchFamilyNameListAction());
	}, []);

	useEffect(() => {
		dispatch(fetchNomineeList());
	}, []);

	return (
		<>

			<Grid item className="ml-50 mr-50 main-nominee">
				<Grid item xs={12} className="mb-30">
					<NomineeHeader />
				</Grid>
				{isLoading ? (
					<Grid container justifyContent="center">
						<Grid item>
							<Spinner />
						</Grid>
					</Grid>
				) : (
					<Grid container spacing={5} className="mb-15 nominee-style">
						{NomineesList?.Policies &&
							NomineesList?.Policies.map((item: any, index: any) => {

								let overallPercentage = 0;
								if (item.Nominees && Array.isArray(item.Nominees)) {
									item.Nominees.forEach((nominee: any) => {
										const percentage = parseInt(nominee.nomineePercentage);
										if (!isNaN(percentage)) {
											overallPercentage += percentage;
										}
									});
								}
								return (
									<Grid
										item
										key={index}
										xs={12}
										sm={6}
										lg={3}
										md={6}
										xl={3}
										className="mb-20"
									>
										<NomineeCard
											productName={item.planName}
											selfName={item.lifeAssuredName}
											sumAssured={item.sumAssured}
											premium={item.totalPremiumAmount
											}
											companyLogo={item.companyLogo}
											nominees={item.Nominees}
											cardData={item}
											isVerified={!item?.Nominees === false}
											overallPercentage={overallPercentage}
											nomineeCount={item?.nomineeCount}
											nomineeEndoFlag={item?.nomineeEndoFlag}
											PermanentAddresses={NomineesList?.PermanentAddresses}
										/>
									</Grid>
								);
							})}
					</Grid>
				)}
			</Grid>
			<ApiModal />
		</>
	);
};

export default NomineeScreen;
