import React, { useState, useEffect, useMemo, memo } from 'react';
import { CircularProgress, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { number } from 'yup';
import CustomButton from '../../../../common/ui/CustomButton';
import theme from '../../../../common/theme';
import FormInput from '../../../../common/ui/Forms/FormInput/FormInput';
import FormCheckBox from '../../../../common/ui/Forms/FormInput/CheckBox/FormCheckBox';
import data from '../../../../common/constants/FormConstants.json';
import CustomSelectBox from '../../../../common/ui/Selectbox/CustomSelect';
import NomineeAddress from './NomineeAddress';
import { ReactComponent as Cancel } from '../../../../common/icons/Cancel-small.svg';
import { ReactComponent as BigOrangeRuppee } from '../../../../common/icons/update-mobile/Uploader_icon.svg';
import '../Nominee.scss';
import {
	fetchFamilyRelationShipList,
	selectFamilyListIsLoading,
	selectFamilyRelationShipList,
	selectIsFamilyRelationShipLoading,
	// selectUserFamilyList,
} from '../../Profile/Profile.slice';
import Spinner from '../../../../common/ui/Spinner';
import Separator from '../../../../common/ui/Separator';
import DatePickerModal from '../../../../common/ui/DatePickerModal';
import config from '../../../../common/config';
import SvgConverter from '../../../../common/SvgConverter';
import ImagePickerModal from '../../../../common/ui/Modal/ImagePickerModal';
import {
	uploadOtherPhotoRequest,
	uploadProfilePhotoRequest,
	uploadSignaturePhotoRequest,
} from '../../ImageUpload/Upload.Slice';
import { setAlertInfo } from '../../Common/Common.slice';
import { ageCalculator, numericOnly } from '../../../../common/utils';
import AppointeeAddress from './AppointeeAddress';
import NomineeUploadModal from './NomineeUploadModal';
import { OVDModal } from '../../Common/OVDModal';
// import { selectNomineeList } from '../Nominee.slice';

const NomineeForm = ({
	index,
	item,
	setValue,
	register,
	errors,
	onRemove,
	watch,
	submit,
	addNomineeModal,
	numberOfForms,
	cardData,

	nomineeCount,
	unregister,
	control,
	getFieldState


}: {
	index: number;
	item?: any;
	setValue: any;
	register: any;
	errors: any;
	onRemove: () => void;
	watch?: any;
	submit: any;
	addNomineeModal: any;
	nomineeCount?: any;
	numberOfForms?: any;
	unregister?: any;
	cardData?: any;
	control: any;
	getFieldState?: any;

}) => {
	const dispatch = useDispatch();

	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const [count, setCount] = useState(true);
	// const [otherUri, setOtherUri] = React.useState<string | any>(null);
	// const [uploadType, setUploadType] = React.useState<string | null>(null);
	const [appointeeDob, setAppointeeDob] = useState('');
	const [appointeeDobValidation, setAppointeeDobValidation] = useState('');
	const [appointeeUpload, setAppointeeUpload] = useState('');
	const [uploadModal, setUploadModal] = useState(false);
	const [OVD, setOVD] = useState(false);

	useEffect(() => {
		dispatch(fetchFamilyRelationShipList())
	}, []);
	const handleImageUploadMore = () => {
		setOVD(true);
		//setUploadModal(true)
		// setOpen(true);
		// setUploadType('OTHER');
	};

	const handleProofDoc = (proofCode: String) => {
		setOVD(false);
		setUploadModal(true);
		if (appointeeUpload === 'appointee') {
			setValue(`nomineeData[${index}].Appointee[0].appointeeProofCode`, proofCode);
		}
		else {
			setValue(`nomineeData[${index}].nomineeProofCode`, proofCode);
		}
	}

	// const familyList = useSelector(selectUserFamilyList);

	let relationShipList = useSelector(selectFamilyRelationShipList);
	// console.log(relationShipList);
	// relationShipList?.push({ relationCode: "Select", relationship: "Select" });
	const isProfileLoading = useSelector(selectFamilyListIsLoading);
	const isRelationShipLoadingList = useSelector(
		selectIsFamilyRelationShipLoading
	);
	const relationName = watch(`nomineeData[${index}].relationshipCode`);

	const image = watch(`nomineeData[${index}].nomineeImageBase64`);
	const appointeeImage = watch(
		`nomineeData[${index}].Appointee[0].appointeeImageBase64`
	);
	useEffect(() => {
		const matchingRelation =
			relationShipList &&
			relationShipList.find((item: any) => item.relationCode === relationName);
		if (matchingRelation) {
			setValue(
				`nomineeData[${index}].relationshipName`,
				matchingRelation?.relationship
			);
		}
	}, [relationName, relationShipList, index, setValue]);

	const relationNameAppointee = watch(
		`nomineeData[${index}].Appointee[0].nomineeRelationship`
	);

	const nomineeFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue = e.target.value;
		// Allow only alphabets and space
		const sanitizedValue = inputValue.replace(/[^a-zA-Z\s]/g, '');
		setValue(`nomineeData.${index}.nomineeFirstName`, sanitizedValue, { shouldValidate: true });
	};

	const nomineeMiddleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue = e.target.value;
		// Allow only alphabets and space
		const sanitizedValue = inputValue.replace(/[^a-zA-Z\s]/g, '');
		setValue(`nomineeData.${index}.nomineeMiddleName`, sanitizedValue, { shouldValidate: true });
	};

	const nomineeLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue = e.target.value;
		// Allow only alphabets and space
		const sanitizedValue = inputValue.replace(/[^a-zA-Z\s]/g, '');
		setValue(`nomineeData.${index}.nomineeLastName`, sanitizedValue, { shouldValidate: true });
	};

	const AppointeeFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue = e.target.value;
		// Allow only alphabets and space
		const sanitizedValue = inputValue.replace(/[^a-zA-Z\s]/g, '');
		setValue(`nomineeData.${index}.Appointee[0].firstName`, sanitizedValue, { shouldValidate: true });
	};

	const AppointeeMiddleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue = e.target.value;
		// Allow only alphabets and space
		const sanitizedValue = inputValue.replace(/[^a-zA-Z\s]/g, '');
		setValue(`nomineeData.${index}.Appointee[0].middleName`, sanitizedValue, { shouldValidate: true });
	};

	const AppointeeLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue = e.target.value;
		// Allow only alphabets and space
		const sanitizedValue = inputValue.replace(/[^a-zA-Z\s]/g, '');
		setValue(`nomineeData.${index}.Appointee[0].lastName`, sanitizedValue, { shouldValidate: true });
	};

	useEffect(() => {
		const matchingRelation =
			relationShipList &&
			relationShipList.find(
				(item: any) => item.relationCode === relationNameAppointee
			);
		if (matchingRelation) {
			setValue(
				`nomineeData[${index}].Appointee[0].relationshipName`,
				matchingRelation?.relationship
			);
		}
	}, [relationNameAppointee, relationShipList, index, setValue]);

	const [pincode, setPincode] = useState('');
	// const [appointeePincode, setappointeePincode] = useState('');
	const [selectedOption, setSelectedOption] = useState('');
	const [appointeeSelectedOption, setAppointeeSelectedOption] = useState('');

	const nomineeRelationship = item?.Appointee?.[0]?.nomineeRelationship;
	const defaultRelationCode =
		nomineeRelationship &&
		relationShipList?.find(
			(relation) => relation?.relationship === nomineeRelationship
		)?.relationCode;

	// const [currentIndex, setCurrentIndex] = useState(0);

	const handleOption = (value: any) => {
		setSelectedOption(value);
		setValue(`nomineeData[${index}].relationshipCode`, value, {
			shouldValidate: true,
		});
	};
	const handleAppointeeOption = (value: any) => {
		setAppointeeSelectedOption(value);
		setValue(`nomineeData[${index}].Appointee[0].relationshipCode`, value, {
			shouldValidate: true,
		});
	};
	const [isChecked, setIsChecked] = useState(
		item?.addressSameAsLifeAssured === 'Y'
	);

	// const [isChecked, setIsChecked] = useState(false);

	const [appointeeIsChecked, setappointeeIsChecked] = useState(
		item?.Appointee?.[0]?.addressSameAsNominee === 'Y'
	);

	const handleChange = (i: any) => {
		const newValue = !isChecked;

		setIsChecked(newValue);
		setValue(`nomineeData[${i}].addressSameAsLifeAssured`, newValue ? 'Y' : 'N', {
			shouldValidate: true,
		});
	};



	const handleAppointeeChange = (i: any) => {
		const newValue = !appointeeIsChecked;

		setappointeeIsChecked(newValue);
		setValue(`nomineeData[${i}].Appointee[0].addressSameAsNominee`, newValue ? 'Y' : 'N',
			{
				shouldValidate: true,
			});
	};

	const processImage = (data: any, i: any) => {
		try {
			if (appointeeUpload === 'appointee') {
				setValue(`nomineeData.${i}.Appointee[0].appointeeImageBase64`, data, {
					shouldValidate: true,
					shouldDirty: true
				});
				setValue(`nomineeData.${i}.Appointee[0].appointeeImageRequired`, 'Y');

				const generateUniqueFileName = () => {
					const timestamp = Date.now();
					const randomId = Math.random().toString(36).substring(2, 8);
					return `image_${timestamp}_${randomId}.jpg`;
				};
				const fileNames = generateUniqueFileName();
				setValue(`nomineeData.${i}.Appointee[0].appointeeImageName`, fileNames);
				setValue(`nomineeData.${i}.Appointee[0].gender`, '');
			} else {
				setValue(`nomineeData.${i}.nomineeImageBase64`, data, {
					shouldValidate: true,
					shouldDirty: true
				});
			}
		} catch (e) {
			// eslint-disable-next-line no-console
		}
	};

	useEffect(() => {
		const base = item?.Appointee?.[0]?.appointeeImageBase64;
		const nomineeBase = item?.nomineeImageBase64;
		setAppointeeDob(`${item.date}/${item.month}/${item.year}`);

		if (item?.Appointee?.[0]?.nomineeRelationship) {

			setValue(
				`nomineeData[${index}].Appointee[0].relationshipName`,
				item?.Appointee?.[0]?.nomineeRelationship
			);

		}

		if (base !== undefined) {
			setValue(`nomineeData.${index}.Appointee[0].appointeeImageBase64`, base);
		}
		if (nomineeBase !== undefined) {
			setValue(`nomineeData.${index}.nomineeImageBase64`, nomineeBase);
		}
	}, [item]);
	useEffect(() => {
		if (isChecked) {
			setValue(`nomineeData.${index}.address1`, item?.PermanentAddress?.address1, {
				shouldValidate: false,
			});
			setValue(`nomineeData.${index}.address2`, item?.PermanentAddress?.address2, {
				shouldValidate: false,
			});
			setValue(`nomineeData.${index}.address3`, item?.PermanentAddress?.address3, {
				shouldValidate: false,
			});
			setValue(`nomineeData.${index}.city`, item?.PermanentAddress?.city, {
				shouldValidate: false,
			});
			setValue(`nomineeData.${index}.pincode`, item?.PermanentAddress?.pincode, {
				shouldValidate: false,
			});
			setValue(`nomineeData.${index}.state`, item?.PermanentAddress?.state, {
				shouldValidate: false,
			});
		} else {
			setValue(`nomineeData.${index}.address1`, item.address1, {
				shouldValidate: false,
			});
			setValue(`nomineeData.${index}.address2`, item.address2, {
				shouldValidate: false,
			});
			setValue(`nomineeData.${index}.address3`, item.address3, {
				shouldValidate: false,
			});
			setValue(`nomineeData.${index}.city`, item.city, {
				shouldValidate: false,
			});
			setValue(`nomineeData.${index}.pincode`, item.pincode, {
				shouldValidate: false,
			});
			setValue(`nomineeData.${index}.state`, item.state, {
				shouldValidate: false,
			});
		}
	}, [isChecked]);


	const nomnieeAddress1 = watch(`nomineeData.${index}.address1`);
	const nomnieeAddress2 = watch(`nomineeData.${index}.address2`);
	const nomnieeAddress3 = watch(`nomineeData.${index}.address3`);
	const nomnieeCity = watch(`nomineeData.${index}.city`);
	const nomnieePincode = watch(`nomineeData.${index}.pincode`);
	const nomnieeState = watch(`nomineeData.${index}.state`);

	useEffect(() => {
		if (count) {
			setCount(false);
			setValue(
				`nomineeData.${index}.Appointee[0].address1_1`,
				item?.Appointee?.[0]?.address1,
				{
					shouldValidate: false,
				}
			);
			setValue(
				`nomineeData.${index}.Appointee[0].address2_1`,
				item?.Appointee?.[0]?.address2,
				{
					shouldValidate: false,
				}
			);
			setValue(
				`nomineeData.${index}.Appointee[0].address3_1`,
				item?.Appointee?.[0]?.address3,
				{
					shouldValidate: false,
				}
			);
			setValue(
				`nomineeData.${index}.Appointee[0].city_1`,
				item?.Appointee?.[0]?.city,
				{
					shouldValidate: false,
				}
			);
			setValue(
				`nomineeData.${index}.Appointee[0].pincode_1`,
				item?.Appointee?.[0]?.pincode,
				{
					shouldValidate: false,
				}
			);
			setValue(
				`nomineeData.${index}.Appointee[0].state_1`,
				item?.Appointee?.[0]?.state,
				{
					shouldValidate: false,
				}
			);
		}
		if (appointeeIsChecked) {


			setValue(
				`nomineeData.${index}.Appointee[0].address1`,
				watch(`nomineeData.${index}.address1`),
				{
					shouldValidate: false,
				}
			);
			setValue(
				`nomineeData.${index}.Appointee[0].address2`,
				watch(`nomineeData.${index}.address2`),
				{
					shouldValidate: false,
				}
			);
			setValue(
				`nomineeData.${index}.Appointee[0].address3`,
				watch(`nomineeData.${index}.address3`),
				{
					shouldValidate: false,
				}
			);
			setValue(
				`nomineeData.${index}.Appointee[0].city`,
				watch(`nomineeData.${index}.city`),
				{
					shouldValidate: false,
				}
			);
			setValue(
				`nomineeData.${index}.Appointee[0].pincode`,
				watch(`nomineeData.${index}.pincode`),
				{
					shouldValidate: false,
				}
			);
			setValue(
				`nomineeData.${index}.Appointee[0].state`,
				watch(`nomineeData.${index}.state`),
				{
					shouldValidate: false,
				}
			);
		} else {
			setValue(
				`nomineeData.${index}.Appointee[0].address1`,
				item?.Appointee?.[0]?.address1_1,
				{
					shouldValidate: false,
				}
			);
			setValue(
				`nomineeData.${index}.Appointee[0].address2`,
				item?.Appointee?.[0]?.address2_1,
				{
					shouldValidate: false,
				}
			);
			setValue(
				`nomineeData.${index}.Appointee[0].address3`,
				item?.Appointee?.[0]?.address3_1,
				{
					shouldValidate: false,
				}
			);
			setValue(
				`nomineeData.${index}.Appointee[0].city`,
				item?.Appointee?.[0]?.city_1,
				{
					shouldValidate: false,
				}
			);
			setValue(
				`nomineeData.${index}.Appointee[0].pincode`,
				item?.Appointee?.[0]?.pincode_1,
				{
					shouldValidate: false,
				}
			);
			setValue(
				`nomineeData.${index}.Appointee[0].state`,
				item?.Appointee?.[0]?.state_1,
				{
					shouldValidate: false,
				}
			);
		}
	}, [appointeeIsChecked, nomnieeAddress1, nomnieeAddress2, nomnieeAddress3, nomnieeCity, nomnieePincode, nomnieeState]);
	// useEffect(() => {
	// 	if (!item) return;
	// 	setValue('nomineeFirstName', item.nomineeFirstName, {
	// 		shouldValidate: true,
	// 	});
	// 	setValue('nomineePercentage', item.nomineePercentage, {
	// 		shouldValidate: true,
	// 	});
	// 	setValue('date', item.date, { shouldValidate: true });
	// 	setValue('month', item.month, { shouldValidate: true });
	// 	setValue('year', item.year, { shouldValidate: true });
	// 	setValue('relationshipName', item.relationshipName, {
	// 		shouldValidate: true,
	// 	});
	// 	setValue('relationshipCode', item.relationshipCode, {
	// 		shouldValidate: true,
	// 	});
	// 	setValue('nomineeSeqID', item.nomineeSeqID || '0', {
	// 		shouldValidate: true,
	// 	});
	// 	setValue('address1', item.address1, { shouldValidate: true });
	// 	setValue('address2', item.address2, { shouldValidate: true });
	// 	setValue('address3', item.address3, { shouldValidate: true });
	// 	setValue('city', item.city, { shouldValidate: true });
	// 	setValue('state', item.state, { shouldValidate: true });
	// 	setValue('pincode', item.pincode, { shouldValidate: true });
	// }, [item]);

	const percentage = watch(`nomineeData[${index}].nomineePercentage`);

	const handleRemove = () => {
		onRemove();
	};

	const dobValue = String(watch('dob')).split('/');
	const dobValueCondition = watch(`nomineeData[${index}].date`);
	const splittedDob = appointeeDob.split('/');
	const isMinor: any = useMemo(
		() =>
			(ageCalculator(
				`${splittedDob[1]}/${splittedDob[0]}/${splittedDob[2]}`
			) as any) < 18,
		[splittedDob]
	);

	useEffect(() => {
		if (!isMinor) {
			setValue(`nomineeData[${index}].Appointee`, [], {
				shouldValidate: false,
			});
		}
	}, [isMinor]);

	const appointeeSplittedDob = appointeeDobValidation.split('/');
	const appointeeIsMinor: any = useMemo(
		() =>
			(ageCalculator(
				`${appointeeSplittedDob[1]}/${appointeeSplittedDob[0]}/${appointeeSplittedDob[2]}`
			) as any) < 18,
		[appointeeSplittedDob]
	);
	const calculateAge = (dob: any) => {
		const today = new Date();
		const birthDate = new Date(dob.split('/').reverse().join('/')); // Reverse the date format for proper parsing
		let age = today.getFullYear() - birthDate.getFullYear();

		// Check if the birthday has occurred this year
		const hasBirthdayOccurred =
			today.getMonth() > birthDate.getMonth() ||
			(today.getMonth() === birthDate.getMonth() &&
				today.getDate() >= birthDate.getDate());

		if (!hasBirthdayOccurred) {
			age--; // Subtract 1 year if birthday hasn't occurred yet
		}

		return age;
	};
	const handleDateChange = (date: any, i: any) => {
		// setCurrentIndex(i);
		setValue(`nomineeData[${i}].date`, date?.format('DD'), {
			shouldValidate: true,
		});
		setValue(`nomineeData[${i}].month`, date?.format('MM'), {
			shouldValidate: true,
		});
		setValue(`nomineeData[${i}].year`, date?.format('YYYY'), {
			shouldValidate: true,
		});
		setAppointeeDob(date?.format('DD/MM/YYYY'));
		const nomineeAge = calculateAge(date?.format('DD/MM/YYYY'));

		setValue(`nomineeData[${i}].nomineeAge`, nomineeAge, {
			shouldValidate: true,
		});
	};

	const handleAppointeeDateChange = (date: any, i: any) => {
		const currentDate = new Date();
		const enteredDate = new Date(date);

		if (
			currentDate.getDate() === enteredDate.getDate() &&
			currentDate.getMonth() === enteredDate.getMonth() &&
			currentDate.getFullYear() === enteredDate.getFullYear()
		) {
			return;
		}
		setValue(`nomineeData[${i}].Appointee[0].date`, date?.format('DD'), {
			shouldValidate: true,
		});
		setValue(`nomineeData[${i}].Appointee[0].month`, date?.format('MM'), {
			shouldValidate: true,
		});
		setValue(`nomineeData[${i}].Appointee[0].year`, date?.format('YYYY'), {
			shouldValidate: true,
		});
		setValue(`nomineeData[${i}].Appointee[0].dob`, date?.format('DD/MM/YYYY'), {
			shouldValidate: true,
		});
		setAppointeeDobValidation(date?.format('DD/MM/YYYY'));
	};
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
		null
	);
	const datePicker = (e: any) => {
		setAnchorEl(e.currentTarget);
	};

	// const handleAddMember = () => {
	// 	const allowedRelationCodes = ['02', '03', '07', '06'];
	// 	const matchingRelationCodes = familyList
	// 		.filter((data: any) => allowedRelationCodes.includes(data?.relationCode))
	// 		.map((data: any) => data?.relationCode);
	// 	return matchingRelationCodes;
	// };

	return (
		<>
			{isProfileLoading ? (
				<>
					{' '}
					<Grid
						className="vh-95"
						container
						justifyContent="center"
						alignItems="center"
					>
						<Grid item className="text-center">
							<CircularProgress />
						</Grid>
					</Grid>
				</>
			) : (
				<>
					<form onSubmit={submit}>
						<Grid
							container
							item
							// xs={12}
							// className="nominee-form"
							key={item.id}
						>
							<Grid item container xs={12}>
								<Grid container justifyContent="space-between" wrap="nowrap">
									<Grid item>
										<Grid container spacing={1}>
											<Grid item className="">
												<Typography color="black" fontSize={28}>
													{index + 1}.
												</Typography>
											</Grid>
											<Grid item className="mt-15">
												<Typography
													color={theme.palette.common.black}
													fontSize={14}
												>
													{t('NOMINEE_NAME')}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid item className=" mt-15" justifyContent="center">
										<Typography
											color={theme.palette.common.black}
											fontSize={14}
											className="ml-n40"
										>
											{percentage}%
										</Typography>
									</Grid>
								</Grid>
								<Grid container justifyContent="space-between">

									<Grid item sm={9} xs={9}>
										<FormInput
											type="text"
											placeholder={t('FIRST_NAME')}
											className="nominee-edit"

											name="nomineeFirstName"
											inputProps={{
												maxLength: config.nameLength,
												className: 'px-10 ',
												// ...register(`nomineeData.${index}.nomineeFirstName`, {
												// 	required: {
												// 		value: true,
												// 		message: t(data.NOMINEENAME),
												// 	},
												// 	validate: (value: any) => {
												// 		if (!config.alphabetsOnlyRegex.test(value)) {
												// 			return t(data.INVALID_NAME);
												// 		}
												// 		return true;
												// 	},
												// }),
												...register(`nomineeData.${index}.nomineeFirstName`, {
													required: {
														value: true,
														message: t(data.NOMINEENAME),
													},
													onChange: nomineeFirstNameChange,
												}),
											}}
											errors={errors}
										/>
										{errors.nomineeData?.[index]?.nomineeFirstName ? (
											<Typography
												color={theme.palette.error.main}
												fontSize={12}
											>
												{errors.nomineeData?.[index]?.nomineeFirstName?.message}
											</Typography>
										) : null}
									</Grid>
									<Grid item sm={2} xs={2}>
										<FormInput
											// type="text"
											placeholder="XX"
											className="px-0"
											name="nomineePercentage"
											inputProps={{
												className: 'px-10',
												maxLength: 3,
												...register(`nomineeData.${index}.nomineePercentage`, {
													min: {
														value: 1,
														message: t('NOMINEE_PERCENT'),
													},
													required: {
														value: true,
														message: t(data.PERCENTAGE),
													},
													pattern: {
														value: config.numbersOnlyRegex,
														message: t(data.INVALID_VALUE),
													},
													onChange: (e: any) => {
														setValue(`nomineeData.${index}.nomineePercentage`, numericOnly(e.target.value), {
															shouldValidate: true,
														});
													},
													validate: (value: any) => {
														if (
															!config.numbersOnlyRegex.test(value) ||
															parseInt(value) > 100
														) {
															return t(data.INVALID_VALUE);
														}
														return true;
													},
												}),
											}}
											errors={errors}
										/>
										{errors.nomineeData?.[index]?.nomineePercentage ? (
											<Typography
												color={theme.palette.error.main}
												fontSize={12}
											>
												{
													errors.nomineeData?.[index]?.nomineePercentage
														?.message
												}
											</Typography>
										) : null}
									</Grid>
								</Grid>
								<Grid container justifyContent="space-between" spacing={1}
									flexWrap="nowrap" className="mt-5 mb-5"
								>
									<Grid item xs={6}>
										<FormInput
											type="text"
											placeholder={t('MIDDLE_NAME')}
											className="nominee-edit"

											name="nomineeMiddleName"
											inputProps={{
												maxLength: config.nameLength,
												className: 'px-10 ',
												// ...register(`nomineeData.${index}.nomineeMiddleName`, {
												// 	validate: (value: any) => {
												// 		if (value !== '' && !config.alphabetsOnlyRegex.test(value)) {
												// 			return t(data.INVALID_NAME);
												// 		}
												// 		return true;
												// 	},
												// }),
												...register(`nomineeData.${index}.nomineeMiddleName`, {
													// required: {
													// 	value: true,
													// 	message: t(data.NOMINEENAME),
													// },
													onChange: nomineeMiddleNameChange,
												}),
											}}
											errors={errors}
										/>
										{errors.nomineeData?.[index]?.nomineeMiddleName ? (
											<Typography
												color={theme.palette.error.main}
												fontSize={12}
											>
												{errors.nomineeData?.[index]?.nomineeMiddleName?.message}
											</Typography>
										) : null}
									</Grid>

									<Grid item xs={6}>
										<FormInput
											type="text"
											placeholder={t('LAST_NAME')}
											className="nominee-edit"

											name="nomineeLastName"
											inputProps={{
												maxLength: config.nameLength,
												className: 'px-10 ',
												// ...register(`nomineeData.${index}.nomineeLastName`, {
												// 	validate: (value: any) => {
												// 		if (value !== '' && !config.alphabetsOnlyRegex.test(value)) {
												// 			return t(data.INVALID_NAME);
												// 		}
												// 		return true;
												// 	},
												// }),
												...register(`nomineeData.${index}.nomineeLastName`, {
													// required: {
													// 	value: true,
													// 	message: t(data.NOMINEENAME),
													// },
													onChange: nomineeLastNameChange,
												}),
											}}
											errors={errors}
										/>
										{errors.nomineeData?.[index]?.nomineeLastName ? (
											<Typography
												color={theme.palette.error.main}
												fontSize={12}
											>
												{errors.nomineeData?.[index]?.nomineeLastName?.message}
											</Typography>
										) : null}
									</Grid>

								</Grid>
								<Grid item container xs={12} rowSpacing={1}>
									<Grid xs={7} lg={6} xl={6} md={6} sm={6}>
										<Grid item xs={12} lg={12} xl={12} md={12} sm={12}>
											<Grid item className="mt-10" display="flex">
												<Typography
													fontSize={14}
													color={theme.palette.common.black}
												>
													{t('DATE_OF_BIRTH')}
												</Typography>

												{isMinor && dobValueCondition && (
													<Typography
														fontSize={14}
														color={theme.palette.error.main}
													>
														(minor)
													</Typography>
												)}
											</Grid>
											<Grid
												container
												justifyContent="space-between"
												alignItems="center"
												columnSpacing={1}
												xs={12}
												item
											>
												<Grid item sm={9} xs={11} lg={9} xl={10} md={9}>
													<Grid
														container
														flexDirection="row"
														spacing={1}
														alignItems="center"
														flexWrap="nowrap"
													>
														<Grid item sm={4}>
															<FormInput
																type="text"
																placeholder={t('DD')}
																className="nominee-edit"
																name="date"
																readOnly
																inputProps={{
																	className: 'p-10',
																	maxLength: 2,
																	...register(`nomineeData.${index}.date`, {
																		required: {
																			value: true,
																			message: t(data.DATE),
																		},
																		validate: (value: any) => {
																			if (
																				!config.numbersOnlyRegex.test(value)
																			) {
																				return t(data.INVALID_VALUE);
																			}
																			return true;
																		},
																	}),
																	value: dobValue?.[1],
																}}
																onClick={datePicker}

																errors={errors}
															/>
															{errors.nomineeData?.[index]?.date ? (
																<Typography
																	color={theme.palette.error.main}
																	fontSize={12}
																>
																	{errors.nomineeData?.[index]?.date?.message}
																</Typography>
															) : null}
														</Grid>

														<Grid item sm={4}>
															<FormInput
																type="text"
																placeholder={t('MM')}
																name="month"
																className="nominee-edit"
																readOnly
																inputProps={{
																	className: 'px-10',
																	maxLength: 2,
																	...register(`nomineeData.${index}.month`, {
																		required: {
																			value: true,
																			message: t(data.MONTH),
																		},
																		validate: (value: any) => {
																			if (
																				!config.numbersOnlyRegex.test(value)
																			) {
																				return t(data.INVALID_VALUE);
																			}
																			return true;
																		},
																	}),
																	value: dobValue?.[1],
																}}
																errors={errors}
																onClick={datePicker}

															/>
															{errors.nomineeData?.[index]?.month ? (
																<Typography
																	color={theme.palette.error.main}
																	fontSize={12}
																>
																	{errors.nomineeData?.[index]?.month?.message}
																</Typography>
															) : null}
														</Grid>
														<Grid item sm={6}>
															<FormInput
																type="text"
																placeholder={t('YYYY')}
																name="year"
																readOnly
																className="nominee-edit"
																inputProps={{
																	className: 'text-center',
																	maxLength: 4,
																	...register(`nomineeData.${index}.year`, {
																		required: {
																			value: true,
																			message: t(data.YEAR),
																		},
																		validate: (value: any) => {
																			if (
																				!config.numbersOnlyRegex.test(value)
																			) {
																				return t(data.INVALID_VALUE);
																			}
																			return true;
																		},
																	}),
																	value: dobValue?.[2],
																}}
																errors={errors}
																onClick={datePicker}

															/>
															{errors.nomineeData?.[index]?.year ? (
																<Typography
																	color={theme.palette.error.main}
																	fontSize={12}
																>
																	{errors.nomineeData?.[index]?.year?.message}
																</Typography>
															) : null}
														</Grid>
													</Grid>
												</Grid>
												<Grid item sm={3} xs={1} lg={3} xl={2} md={3}>
													<Grid item>

														<DatePickerModal
															iconType="primary"
															disableFuture

															onChange={(date: any) => {
																handleDateChange(date, index);
															}}
															modalOpen={anchorEl}
															setModalOpen={setAnchorEl}

														/>

													</Grid>
													<FormInput
														hidden
														name="dob"
														inputProps={{
															...register('dob'),
														}}
														errors={errors}
													/>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={12} lg={12} xl={12} md={12} sm={12}>
											<Grid item className="mt-10">
												<Typography
													className="f-14"
													color={theme.palette.common.black}
												>
													{t('RELATIONSHIP')}
												</Typography>
											</Grid>
											<Grid item sm={12} lg={12} xl={12} md={12} xs={11}>
												{isRelationShipLoadingList ? (
													<Grid item>
														<Spinner />
													</Grid>
												) : (
													<>
														<CustomSelectBox
															// relationCode={'02'}
															// label='Select'
															// disable={handleAddMember()}
															ClassName="w-100 selectbox-border"
															options={
																relationShipList?.map((row: any) => ({
																	Code: row.relationCode,
																	Description: row.relationship,
																	Category: row.relationship,
																})) || []
															}

															inputProps={register(
																`nomineeData.${index}.relationshipCode`,
																{
																	required: {
																		value: true,
																		message: t(data.RELATIONSHIP),
																	},
																}
															)}
															// relationDisable={['02', '03', '07', '06'].includes(
															// 	item.relationshipCode
															// )}
															defaultValue={
																selectedOption || item.relationshipCode
															}
															setSelectedOption={handleOption}
														/>
													</>
												)}
												{errors.nomineeData?.[index]?.relationshipCode && (
													<Typography
														color={theme.palette.error.main}
														fontSize={12}
													>
														{
															errors.nomineeData?.[index]?.relationshipCode
																?.message
														}
													</Typography>
												)}
											</Grid>
										</Grid>
									</Grid>
									<Grid xs={5} lg={6} xl={6} md={6} sm={6}>
										<Grid
											container
											item
											xs={11}
											className="finance-border mt-25 ml-10"
											alignItems="center"
											display="flex"
											flexDirection="column"
										>
											{image ? (
												<>
													<img
														src={`data:image/png/jpg/jpeg;base64,${image}`}
														alt="Canceled Cheque"
														width="150"
														height="150"
													/>
													<CustomButton
														text="TAP TO RE UPLOAD"
														variant="text"
														color="primary"
														showIcon={false}
														fontSize={14}
														onClick={() => {
															handleImageUploadMore();
															setAppointeeUpload('nominee');
														}}
													/>
												</>
											) : (
												<>
													<Grid item mt={6}>
														<Typography className="pointer" onClick={() => {
															handleImageUploadMore();
															setAppointeeUpload('nominee');
														}}>
															<SvgConverter
																Icon={BigOrangeRuppee}
																className="ml-6 mt-5"
															/>
														</Typography>
													</Grid>

													<Grid item mb={6}>
														<CustomButton
															text="TAP TO UPLOAD ID PROOF"
															variant="text"
															color="primary"
															showIcon={false}
															fontSize={14}
															onClick={() => {
																handleImageUploadMore();
																setAppointeeUpload('nominee');
															}}
														/>
													</Grid>
												</>
											)}
										</Grid>
										{!image && (
											<Typography
												className="f-12 ml-20"
												color={theme.palette.error.main}
											>
												{/* Photo is mandatory */}
												{t('PHOTO_IS_MANDATORY')}
											</Typography>
										)}
										{image && errors.nomineeData?.[index]?.nomineeImageBase64 && (!getFieldState(`nomineeData[${index}].nomineeImageBase64`).isDirty) && (
											<Typography
												className="f-12 fw-600 ml-20"
												color={theme.palette.error.main}
											>
												{errors.nomineeData?.[index]?.nomineeImageBase64?.message}
											</Typography>
										)}
									</Grid>
								</Grid>

								<Grid
									xs={12}
									item
									container
									spacing={1}
									justifyContent="space-between"
									flexWrap="nowrap"
									className="mt-2"
								>
									<Grid item>
										<Typography
											className="f-14"
											color={theme.palette.common.black}
										>
											{t('ADDRESS')}
										</Typography>
									</Grid>
									<Grid item className="mt-n2 ">
										<FormCheckBox
											label={
												<Typography
													className="f-16"
													color={theme.palette.grey[700]}
												>
													{t('SAME_AS_LIFE_ASSURED')}
												</Typography>
											}
											checked={isChecked}
											onChange={() => handleChange(index)}
											size={16}
											checkBoxPadding={1}
										/>
									</Grid>
								</Grid>

								<Grid item xs={12}>
									<NomineeAddress
										index={index}
										item={item}
										register={register}
										errors={errors.nomineeData?.[index]}
										submit={submit}
										pincode={pincode}
										setPincode={setPincode}
										setValue={setValue}
										control={control}
										watch={watch}
										isChecked={isChecked}
									/>
								</Grid>


								{numberOfForms > 1 && (
									<>
										<Grid item className="mt-20">
											<CustomButton
												className=" f-14"
												text={t('REMOVE_THIS_NOMINEE')}
												variant="text"
												color="primary"
												showIcon={false}
												startIcon={Cancel}
												onClick={handleRemove}
												fontWeight={400}
											/>
										</Grid>
										<Grid item xs={12} className="py-20">
											<Separator color={theme.palette.common.black} />
										</Grid>
									</>
								)}
							</Grid>
						</Grid>
						{/* <ImagePickerModal
							open={open}
							setOpen={setOpen}
							handleImage={(data: any, file: any) =>
								processImage(data, file, index)
							}
							disableWebcam
						/> */}
					</form>

					{isMinor && dobValueCondition && (
						<Grid
							container
							item
							// xs={12}
							// className="nominee-form"
							style={{ backgroundColor: '#f2f2f2' }}
							justifyContent="center"
							key={item.id}
							mt={5}
						>
							<Grid item container xs={11}>
								<Grid container justifyContent="space-between" wrap="nowrap">
									<Grid item>
										<Grid container spacing={1}>
											{/* <Grid item className="">
												<Typography color="black" fontSize={28}>
													{index + 1}.
												</Typography>
											</Grid> */}
											<Grid item className="mt-15">
												<Typography
													color={theme.palette.common.black}
													fontSize={14}
												>
													{t('APPOINTEE_NAME')}
													{/* Appointee Name */}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									{/* <Grid item className=" mt-15" justifyContent="center">
										<Typography
											color={theme.palette.common.black}
											fontSize={14}
											className="ml-n40"
										>
											{percentage}%
										</Typography>
									</Grid> */}
								</Grid>
								<Grid container justifyContent="space-between">
									<Grid item sm={12} xs={12}>
										<FormInput
											type="text"
											placeholder="First Name"
											className="nominee-edit"
											name="firstName"
											inputProps={{
												maxLength: config.nameLength,
												className: 'px-10 ',
												// ...register(
												// 	`nomineeData.${index}.Appointee[0].firstName`,
												// 	{
												// 		required: {
												// 			value: true,
												// 			message: t(data.APPOINTEENAME),
												// 		},
												// 		validate: (value: any) => {
												// 			if (!config.alphabetsOnlyRegex.test(value)) {
												// 				return t(data.INVALID_NAME);
												// 			}
												// 			return true;
												// 		},
												// 	}
												// ),
												...register(`nomineeData.${index}.Appointee[0].firstName`, {
													required: {
														value: true,
														message: t(data.APPOINTEENAME),
													},
													onChange: AppointeeFirstNameChange,
												}),
											}}
											errors={errors}
										/>
										{errors.nomineeData?.[index]?.Appointee?.[0]?.firstName ? (
											<Typography
												color={theme.palette.error.main}
												fontSize={12}
											>
												{
													errors.nomineeData?.[index]?.Appointee?.[0]?.firstName
														.message
												}
											</Typography>
										) : null}
									</Grid>
								</Grid>

								<Grid container justifyContent="space-between" spacing={1}
									flexWrap="nowrap" className="mt-5 mb-5"
								>
									<Grid item xs={6}>
										<FormInput
											type="text"
											placeholder="Middle Name"
											className="nominee-edit"
											name="middleName"
											inputProps={{
												maxLength: config.nameLength,
												className: 'px-10 ',
												// ...register(
												// 	`nomineeData.${index}.Appointee[0].middleName`,
												// 	{
												// 		validate: (value: any) => {
												// 			if (value !== '' && !config.alphabetsOnlyRegex.test(value)) {
												// 				return t(data.INVALID_NAME);
												// 			}
												// 			return true;
												// 		},
												// 	}
												// ),
												...register(`nomineeData.${index}.Appointee[0].middleName`, {
													// required: {
													// 	value: true,
													// 	message: t(data.APPOINTEENAME),
													// },
													onChange: AppointeeMiddleNameChange,
												}),
											}}
											errors={errors}
										/>
										{errors.nomineeData?.[index]?.Appointee?.[0]?.middleName ? (
											<Typography
												color={theme.palette.error.main}
												fontSize={12}
											>
												{
													errors.nomineeData?.[index]?.Appointee?.[0]?.middleName
														.message
												}
											</Typography>
										) : null}
									</Grid>

									<Grid item xs={6}>
										<FormInput
											type="text"
											placeholder="Last Name"
											className="nominee-edit"
											name="lastName"
											inputProps={{
												maxLength: config.nameLength,
												className: 'px-10 ',
												// ...register(
												// 	`nomineeData.${index}.Appointee[0].lastName`,
												// 	{
												// 		validate: (value: any) => {
												// 			if (value !== '' && !config.alphabetsOnlyRegex.test(value)) {
												// 				return t(data.INVALID_NAME);
												// 			}
												// 			return true;
												// 		},
												// 	}
												// ),
												...register(`nomineeData.${index}.Appointee[0].lastName`, {
													// required: {
													// 	value: true,
													// 	message: t(data.APPOINTEENAME),
													// },
													onChange: AppointeeLastNameChange,
												}),
											}}
											errors={errors}
										/>
										{errors.nomineeData?.[index]?.Appointee?.[0]?.lastName ? (
											<Typography
												color={theme.palette.error.main}
												fontSize={12}
											>
												{
													errors.nomineeData?.[index]?.Appointee?.[0]?.lastName
														.message
												}
											</Typography>
										) : null}
									</Grid>

								</Grid>
								<Grid item container xs={12} rowSpacing={1}>
									<Grid xs={7} lg={6} xl={6} md={6} sm={6}>
										<Grid item xs={12} lg={12} xl={12} md={12} sm={12}>
											<Grid item className="mt-10">
												<Typography
													fontSize={14}
													color={theme.palette.common.black}
												>
													{t('DATE_OF_BIRTH')}
												</Typography>
											</Grid>
											<Grid
												container
												justifyContent="space-between"
												alignItems="center"
												columnSpacing={1}
												xs={12}
												item
											>
												<Grid item sm={9} xs={11} lg={9} xl={10} md={9}>
													<Grid
														container
														flexDirection="row"
														spacing={1}
														alignItems="center"
														flexWrap="nowrap"
													>
														<Grid item sm={4}>
															<FormInput
																type="text"
																placeholder={t('DD')}
																className="nominee-edit"
																name="date"
																readOnly
																inputProps={{
																	className: 'p-10',
																	maxLength: 2,
																	...register(
																		`nomineeData.${index}.Appointee[0].date`,
																		{
																			required: {
																				value: true,
																				message: t(data.DATE),
																			},
																			validate: (value: any) => {
																				if (
																					!config.numbersOnlyRegex.test(value)
																				) {
																					return t(data.INVALID_VALUE);
																				}
																				return true;
																			},
																		}
																	),
																	value: dobValue?.[1],
																}}
																onClick={datePicker}

																errors={errors}
															/>
															{errors.nomineeData?.[index]?.Appointee?.[0]
																?.date ? (
																<Typography
																	color={theme.palette.error.main}
																	fontSize={12}
																>
																	{
																		errors.nomineeData?.[index]?.Appointee?.[0]
																			?.date?.message
																	}
																</Typography>
															) : null}
														</Grid>

														<Grid item sm={4}>
															<FormInput
																type="text"
																placeholder={t('MM')}
																name="month"
																className="nominee-edit"
																readOnly
																inputProps={{
																	className: 'px-10',
																	maxLength: 2,
																	...register(
																		`nomineeData.${index}.Appointee[0].month`,
																		{
																			required: {
																				value: true,
																				message: t(data.MONTH),
																			},
																			validate: (value: any) => {
																				if (
																					!config.numbersOnlyRegex.test(value)
																				) {
																					return t(data.INVALID_VALUE);
																				}
																				return true;
																			},
																		}
																	),
																	value: dobValue?.[1],
																}}
																errors={errors}
																onClick={datePicker}

															/>
															{errors.nomineeData?.[index]?.Appointee?.[0]
																?.month ? (
																<Typography
																	color={theme.palette.error.main}
																	fontSize={12}
																>
																	{
																		errors.nomineeData?.[index]?.Appointee?.[0]
																			?.month?.message
																	}
																</Typography>
															) : null}
														</Grid>
														<Grid item sm={6}>
															<FormInput
																type="text"
																placeholder={t('YYYY')}
																name="year"
																readOnly
																className="nominee-edit"
																inputProps={{
																	className: 'text-center',
																	maxLength: 4,
																	...register(
																		`nomineeData.${index}.Appointee[0].year`,
																		{
																			required: {
																				value: true,
																				message: t(data.YEAR),
																			},
																			validate: (value: any) => {
																				if (
																					!config.numbersOnlyRegex.test(value)
																				) {
																					return t(data.INVALID_VALUE);
																				}
																				return true;
																			},
																		}
																	),
																	value: dobValue?.[2],
																}}
																errors={errors}
																onClick={datePicker}

															/>
															{errors.nomineeData?.[index]?.Appointee?.[0]
																?.year ? (
																<Typography
																	color={theme.palette.error.main}
																	fontSize={12}
																>
																	{
																		errors.nomineeData?.[index]?.Appointee?.[0]
																			?.year?.message
																	}
																</Typography>
															) : null}
														</Grid>
													</Grid>
													<Grid>
														<FormInput
															hidden
															name="dob"
															inputProps={{
																...register(
																	`nomineeData.${index}.Appointee[0].dob`
																),
															}}
															errors={
																appointeeIsMinor &&
																	appointeeSplittedDob.length > 1
																	? {
																		dob: {
																			message: t('AGE_IS_BELOW_18_YEARS'),
																		},
																	}
																	: errors
															}
														/>
													</Grid>
												</Grid>
												<Grid item sm={3} xs={1} lg={3} xl={2} md={3}>
													<Grid item>

														<DatePickerModal
															iconType="primary"
															disableFuture
															onChange={(date: any) => {
																handleAppointeeDateChange(date, index);
															}}
															modalOpen={anchorEl}
															setModalOpen={setAnchorEl}
															disableBelow18
														/>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={12} lg={12} xl={12} md={12} sm={12}>
											<Grid item className="mt-10">
												<Typography
													className="f-14"
													color={theme.palette.common.black}
												>
													{t('RELATIONSHIP')}
												</Typography>
											</Grid>
											<Grid item sm={12} lg={12} xl={12} md={12} xs={11}>
												{isRelationShipLoadingList ? (
													<Grid item>
														<Spinner />
													</Grid>
												) : (
													<>
														<CustomSelectBox
															// relationCode={'02'}

															// disable={handleAddMember()}
															// label='Select'
															ClassName="w-100 selectbox-border"
															options={

																relationShipList?.map((row: any) => ({
																	Code: row.relationCode,
																	Description: row.relationship,
																	Category: row.relationship,
																})) || []
															}
															inputProps={register(
																`nomineeData.${index}.Appointee[0].nomineeRelationship`,
																{
																	required: {
																		value: true,
																		message: t(data.RELATIONSHIP),
																	},
																}
															)}
															// relationDisable={['02', '03', '07', '06'].includes(
															// 	item.relationshipCode
															// )}
															defaultValue={
																appointeeSelectedOption || defaultRelationCode
															}
															setSelectedOption={handleAppointeeOption}
														/>
													</>
												)}
												{errors.nomineeData?.[index]?.Appointee?.[0]
													?.nomineeRelationship && (
														<Typography
															color={theme.palette.error.main}
															fontSize={12}
														>
															{
																errors.nomineeData?.[index]?.Appointee?.[0]
																	?.nomineeRelationship?.message
															}
														</Typography>
													)}
											</Grid>
										</Grid>
									</Grid>
									<Grid xs={5} lg={6} xl={6} md={6} sm={6}>
										<Grid
											container
											item
											xs={11}
											className="finance-border mt-25 ml-10"
											alignItems="center"
											display="flex"
											flexDirection="column"
										>
											{appointeeImage ? (
												<>
													<img
														src={`data:image/png/jpg/jpeg;base64,${appointeeImage}`}
														alt="Canceled Cheque"
														width="150"
														height="150"
													/>
													<CustomButton
														text="TAP TO RE-UPLOAD"
														variant="text"
														color="primary"
														showIcon={false}
														fontSize={14}
														onClick={() => {
															handleImageUploadMore();
															setAppointeeUpload('appointee');
														}}
													/>
												</>
											) : (
												<>
													<Grid item mt={6}>
														<Typography className="pointer" onClick={() => {
															handleImageUploadMore();
															setAppointeeUpload('appointee');
														}}>
															<SvgConverter
																Icon={BigOrangeRuppee}
																className="ml-6 mt-5"
															/>
														</Typography>
													</Grid>

													<Grid item mb={6}>
														<CustomButton
															text="TAP TO UPLOAD ID PROOF"
															variant="text"
															color="primary"
															showIcon={false}
															fontSize={14}
															onClick={() => {
																handleImageUploadMore();
																setAppointeeUpload('appointee');
															}}
														/>
													</Grid>
												</>
											)}
										</Grid>

										{!appointeeImage && (
											<Typography
												className="f-12 ml-20"
												color={theme.palette.error.main}
											>
												Photo is mandatory
											</Typography>
										)}
										{appointeeImage && errors.nomineeData?.[index]?.Appointee?.[0].appointeeImageBase64 && (!getFieldState(`nomineeData[${index}].Appointee.[0].appointeeImageBase64`).isDirty) && (
											<Typography
												className="f-12 fw-600 ml-20"
												color={theme.palette.error.main}
											>
												{errors.nomineeData?.[index]?.Appointee?.[0].appointeeImageBase64?.message}
											</Typography>
										)}
									</Grid>
								</Grid>

								<Grid
									xs={12}
									item
									container
									spacing={1}
									justifyContent="space-between"
									flexWrap="nowrap"
									className="mt-2"
								>
									<Grid item>
										<Typography
											className="f-14"
											color={theme.palette.common.black}
										>
											{t('ADDRESS')}
										</Typography>
									</Grid>
									<Grid item className="mt-n2 ">
										<FormCheckBox
											label={
												<Typography
													className="f-16"
													color={theme.palette.grey[700]}
												>
													Same as nominee
												</Typography>
											}
											checked={appointeeIsChecked}
											onChange={(e) => {
												handleAppointeeChange(index);
											}}
											size={16}
											checkBoxPadding={1}

										/>
									</Grid>
								</Grid>

								<Grid item xs={12}>
									<AppointeeAddress
										index={index}
										item={item}
										register={register}
										errors={errors.nomineeData?.[index]?.Appointee?.[0]}
										submit={submit}
										// pincode={appointeePincode}
										// setPincode={setappointeePincode}
										setValue={setValue}
										appointeeIsChecked={appointeeIsChecked}
									/>
								</Grid>


								{/* <Grid item className="mt-20">
									<CustomButton
										className=" f-14"
										text={t('REMOVE_THIS_NOMINEE')}
										variant="text"
										color="primary"
										showIcon={false}
										startIcon={Cancel}
										onClick={handleRemove}
										fontWeight={400}
									/>
								</Grid> */}
								<Grid item xs={12} className="py-20">
									<Separator color={theme.palette.common.black} />
								</Grid>
							</Grid>
						</Grid>
					)}


					<NomineeUploadModal open={uploadModal} setOpen={setUploadModal} submit={(data: any) => processImage(data, index)} IdProof={appointeeUpload === 'appointee' ? watch(`nomineeData[${index}].Appointee[0].appointeeProofCode`) : watch(`nomineeData[${index}].nomineeProofCode`)} />
					<OVDModal open={OVD} setOpen={setOVD} selectDoc={handleProofDoc} />
				</>
			)}
		</>
	);
};

export default memo(NomineeForm);
