import { Card, CardContent, Grid, IconButton, Typography, useTheme } from "@mui/material"
import SvgConverter from "../../../../common/SvgConverter";
import { ReactComponent as AlertIconRed } from '../../../../common/icons/warningRed.svg.svg';
import { APP_ROUTES } from "../../../../common/constants/Routes";
import { ReactComponent as Arrow } from '../../../../common/icons/right_arrow-icon.svg';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchPolicyDocumentDownload } from "../../PolicyScreen/Policies.slice";
import { useState } from "react";
import { fetchMissingInfo, fetchPolicyRejection, selectIsPolicyRejection } from "../Dashboard.slice";
import CustomButton from "../../../../common/ui/CustomButton";

const MissingInfoCard = ({ flag, insIntegFlag, data }: any) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const policyRejectionLoading = useSelector(selectIsPolicyRejection);

    const downloadAnnualAccountStatement = () => {

        const handleSuccess = () => {
            dispatch(fetchMissingInfo({}))
        }

        dispatch(
            fetchPolicyDocumentDownload({
                annualAccountStatement: 'Y',
                documentFlag: 'ANNUAL_ACCOUNT_STATEMENT',
                t,
                handleSuccess
            } as any)
        );
        setOpen(false);
    };

    const updatePolicyRejection = () => {

        const payload = {
            msgId: data?.msgId,
            flag: "PR",
            amcCode: data?.amcCode,
            policyNo: data?.policyNo,
            policyType: data?.policyType
        }

        dispatch(fetchPolicyRejection(payload));
        window.scrollTo(0, 0)
    }

    return (<Grid className="mt-5 p-5">
        <Card
            sx={{
                // width: 300,
                backgroundColor: theme.palette.grey[200],
            }}
            variant="outlined"
        >
            <CardContent >
                <Grid display="flex" className=" ">
                    <Grid>
                        <SvgConverter Icon={AlertIconRed} />{' '}
                    </Grid>

                    <Grid>
                        <Typography
                            color={theme.palette.error.main}
                            className=" fw-600 px-10"
                        >
                            {flag === 'P' ? t('Permanent Address Mismatch') : flag === 'N' ? t('NOMINEE_UPDATE_NEEDED') : flag === 'A' ? t('DOWNLOAD_ANNUAL_ACCOUNT') : flag === 'R' ? t('Policy Rejection') : t('MISSING_INFORMATION')}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container xs={12}>
                    <Grid item className="mt-5" xs={10}>
                        <Typography className="f-14 fw-6 px-30" color="black">
                            {flag === 'P' ? t('eIA permanent address and Policy <Policy_No.> permanent address is different. Please review and update.')?.replace("<Policy_No.>", data?.policyNo) : flag === 'N' ? t('PLEASE_UDATE').replace("<family_member>", data?.relationship) : flag === 'A' ? t('PLEASE_CLICK_TO_VIEW/DOWNLOAD') : flag === 'R' ? t(`Policy No. ${data?.policyNo} rejected due to ${data?.msgDescription}`) : t('PLEASE_PROVIDE_FAMILY')}
                        </Typography>
                    </Grid>
                    <Grid item xs={1} className="py-12" >
                        {flag === 'N' &&
                            <Link to={APP_ROUTES.NOMINEE}>
                                <SvgConverter
                                    Icon={Arrow}
                                    className="right-arrow ml-13"
                                />
                            </Link>}

                        {flag === 'P' &&
                            <Link to={data?.inprogressStatus === "Y" ? APP_ROUTES.MANAGE_ADDRESS : APP_ROUTES.ADDRESS_MISMATCH}>
                                <SvgConverter
                                    Icon={Arrow}
                                    className="right-arrow ml-13"
                                />
                            </Link>}

                        {flag === 'M' &&
                            <Link to={insIntegFlag == 'Y' ? APP_ROUTES.NOMINEE : APP_ROUTES.FAMILYDETAILS}>
                                <SvgConverter
                                    Icon={Arrow}
                                    className="right-arrow ml-13"
                                />
                            </Link>}

                        {flag === 'A' &&
                            <IconButton
                                className="p-0"
                                onClick={downloadAnnualAccountStatement}
                            >
                                <SvgConverter
                                    Icon={Arrow}
                                    className="right-arrow ml-13"
                                />
                            </IconButton>
                        }

                        {flag === 'R' &&
                            <CustomButton
                                text={t('ok')}
                                onClick={updatePolicyRejection}
                                variant="text"
                                color="secondary"
                                showIcon={false}
                                className="f-16 fw-600"
                                loading={policyRejectionLoading}
                            />
                        }
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    </Grid>)
}

export default MissingInfoCard;