import { axiosInstance } from '../../../common/axiosInstance';
import { NomineeRoutes } from '../../../common/routes';

const axios = axiosInstance;
const fetchNomineeDataList = (data: any) =>
	axiosInstance.request({ ...NomineeRoutes.nominesLists, data });

const editNomineeData = (data: any) =>
	axios.request({ ...NomineeRoutes.editNominee, data });

const fetchFamiliesNameList = (data: any) =>
	axios.request({ ...NomineeRoutes.familiesList, data });

const fetchProofmasterList = (data: any) =>
	axios.request({ ...NomineeRoutes.proofmasterList, data });

export { fetchNomineeDataList, editNomineeData, fetchFamiliesNameList, fetchProofmasterList };
