import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import "../Legal/legal.scss";
import { APP_ROUTES } from '../../../common/constants/Routes';
import { ReactComponent as ArrowLeftIcon } from '../../../common/icons/left_arrow-icon.svg';
import SvgConverter from '../../../common/SvgConverter';

const SudScreen = () => {
    const { t } = useTranslation();
    const Location = useLocation();
    const navigate = useNavigate();

    const url = Location?.state?.PaymentURL;

    useEffect(() => {
        if (!url) {
            navigate(APP_ROUTES.DASHBOARD);
        }
    }, [url])

    return (
        <Grid container>
            <Grid item sm={12} xs={12} lg={12} md={12}>

                <Grid item>
                    <span className="arrow-span">
                        <Link to={APP_ROUTES.DASHBOARD}>
                            <SvgConverter
                                Icon={ArrowLeftIcon}
                                className="left-arrow-style"
                            />
                        </Link>
                    </span>
                </Grid>

                <Grid item>
                    <iframe src={url} style={{ width: '100%', height: '500px' }} />
                </Grid>


            </Grid>
        </Grid>
    );
};
export default SudScreen;