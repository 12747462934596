import { createSlice } from '@reduxjs/toolkit';
import { LoadingState } from '../../../common';
import { RootState } from '../../../common/store';
import { DashboardDetails, SupportOption } from './types/Dashboard.model';

interface DashboardSlice {
	isLoading: LoadingState;
	dashboardDetails: {
		status: LoadingState;
		requestInfo: DashboardDetails[] | any;
		error: any;
	};
	errors: any | null;
	dashboard: any | null;
	submitSupport: {
		isLoading: LoadingState;
		errors: any;
		value: any;
	};
	supportOptions: {
		data: SupportOption[];
		isLoading: LoadingState;
		errors: any;
	};
	missingInfo: {
		data: any;
		isLoading: LoadingState;
		errors: any;
	};
	policyRejection: {
		data: any;
		isLoading: LoadingState;
		errors: any;
	};
}

const initialState: DashboardSlice = {
	isLoading: 'idle',
	dashboardDetails: {
		status: 'idle',
		requestInfo: [],
		error: '',
	},
	errors: null,
	dashboard: null,
	submitSupport: {
		isLoading: 'idle',
		value: undefined,
		errors: undefined,
	},
	supportOptions: {
		data: [],
		isLoading: 'idle',
		errors: undefined,
	},
	missingInfo: {
		data: [],
		isLoading: "idle",
		errors: undefined
	},
	policyRejection: {
		data: [],
		isLoading: "idle",
		errors: undefined
	}
};

const dashboardSlice = createSlice({
	name: 'dashboard',
	initialState,
	reducers: {
		dashboardListRequest: (state, action) => {
			state.dashboardDetails.status = 'loading';
			state.dashboardDetails.requestInfo = [];
			state.dashboardDetails.error = null;
		},
		dashboardListSuccess: (state, action) => {
			state.dashboardDetails.status = 'done';
			state.dashboardDetails.requestInfo = action.payload;
			state.dashboardDetails.error = null;
		},
		dashboardListError: (state, action) => {
			state.dashboardDetails.status = 'done';
			state.dashboardDetails.requestInfo = [];
			state.dashboardDetails.error = action.payload;
		},
		reportIsSubmittingRequest: (state) => {
			state.submitSupport.errors = undefined;
			state.submitSupport.isLoading = 'loading';
			state.submitSupport.value = undefined;
		},
		reportSubmitSupportRequestSuccess: (state, action) => {
			state.submitSupport.errors = undefined;
			state.submitSupport.isLoading = 'done';
			state.submitSupport.value = action.payload;
		},
		reportSubmitSupportRequestError: (state, action) => {
			state.submitSupport.errors = action.payload;
			state.submitSupport.isLoading = 'done';
			state.submitSupport.value = undefined;
		},
		fetchSupportOptions: (state) => {
			state.supportOptions.errors = undefined;
			state.supportOptions.isLoading = 'loading';
			state.supportOptions.data = [];
		},
		reportFetchSupportOptionsSuccess: (state, actions) => {
			state.supportOptions.errors = undefined;
			state.supportOptions.isLoading = 'done';
			state.supportOptions.data = actions.payload;
		},
		reportFetchSupportOptionsError: (state, actions) => {
			state.supportOptions.errors = actions.payload;
			state.supportOptions.isLoading = 'error';
			state.supportOptions.data = [];
		},
		fetchMissingInfo: (state, actions) => {
			state.missingInfo.errors = undefined;
			state.missingInfo.isLoading = 'loading';
			state.missingInfo.data = [];
		},
		missingInfoSuccess: (state, actions) => {
			state.missingInfo.errors = undefined;
			state.missingInfo.isLoading = 'done';
			state.missingInfo.data = actions.payload;
		},
		missingInfoError: (state, actions) => {
			state.supportOptions.errors = actions.payload;
			state.supportOptions.isLoading = 'error';
			state.supportOptions.data = [];
		},
		fetchPolicyRejection: (state, actions) => {
			state.policyRejection.errors = undefined;
			state.policyRejection.isLoading = 'loading';
			state.policyRejection.data = [];
		},
		policyRejectionSuccess: (state, actions) => {
			state.policyRejection.errors = undefined;
			state.policyRejection.isLoading = 'done';
			state.policyRejection.data = actions.payload;
		},
		policyRejectionError: (state, actions) => {
			state.policyRejection.errors = actions.payload;
			state.policyRejection.isLoading = 'error';
			state.policyRejection.data = [];
		}

	}
});

export const selectIsLoading = (state: RootState) =>
	state.dashboard.isLoading === 'loading';

export const selectDashboardListError = (state: RootState) =>
	state.dashboard.errors;

export const selectIsDashboardLoading = (state: RootState) =>
	state.dashboard.dashboardDetails.status === 'loading';

export const DashboardList = (state: RootState) =>
	state.dashboard.dashboardDetails.requestInfo;
export const selectIsSubmitSupportUpdating = (state: RootState) =>
	state.dashboard.submitSupport.isLoading === 'loading';

export const selectIsSubmitSupportUpdateSuccess = (state: RootState) =>
	state.dashboard.submitSupport.value;

export const selectIsSubmitSupportUpdateError = (state: RootState) =>
	state.dashboard.submitSupport.errors;

export const selectIsSupportOptionsLoading = (state: RootState) =>
	state.dashboard.supportOptions.isLoading === 'loading';

export const selectSupportOptions = (state: RootState) =>
	state.dashboard.supportOptions;

export const selectSupportOptionsError = (state: RootState) =>
	state.dashboard.supportOptions.errors;

export const selectIsMissingInfoLoading = (state: RootState) =>
	state.dashboard.missingInfo.isLoading === 'loading';

export const selectMissingInformation = (state: RootState) =>
	state.dashboard.missingInfo.data.EnableMssingInformation || [];

export const selectAnnualStatusViewedFlag = (state: RootState) =>
	state.dashboard.missingInfo.data.annualStatusViewedFlag === 'N' || state.dashboard.missingInfo.data.annualStatusViewedFlag === '';

export const selectNomineeUpdateNeeded = (state: RootState) =>
	state.dashboard.missingInfo.data.NomineeUpdateNeeded;

export const selectIsPolicyRejection = (state: RootState) =>
	state.dashboard.policyRejection.isLoading === 'loading';

export const selectPolicyRejection = (state: RootState) =>
	state.dashboard.missingInfo.data.PolicyRejections;

export const selectAddressMismatch = (state: RootState) =>
	state.dashboard.missingInfo?.data?.EnableAddressMismatch;

export const {
	dashboardListRequest,
	dashboardListSuccess,
	dashboardListError,
	reportIsSubmittingRequest,
	reportSubmitSupportRequestError,
	reportSubmitSupportRequestSuccess,
	fetchSupportOptions,
	reportFetchSupportOptionsError,
	reportFetchSupportOptionsSuccess,
	fetchMissingInfo,
	missingInfoSuccess,
	missingInfoError,
	fetchPolicyRejection,
	policyRejectionSuccess,
	policyRejectionError
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
