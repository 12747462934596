import { Grid, Typography } from "@mui/material"
import CommonModal from "../../../../common/ui/Modal/CommonModal"
import CustomButton from "../../../../common/ui/CustomButton"
import { useTranslation } from "react-i18next"
import { ReactComponent as cancel } from '../../../../common/icons/cancel-icon.svg';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../../../common/constants/Routes";
import cryptoEncryptionDecryption from "../../../../common/crypto";
import { UpdateAddressMismatchedPoliciesRequest, getAddressEkycUrlRequest, selectAddressMismatchIsLoading, selectGetAddressEkycUrlIsLoading } from "../../AddressModule/Address.slice";

export const AddressMismatchModal = ({ open, setOpen, select }: any) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const ekycLoading = useSelector(selectGetAddressEkycUrlIsLoading);
    const addressMismatchLoading = useSelector(selectAddressMismatchIsLoading);

    const submit = () => {
        const handleDone = () => navigate(APP_ROUTES.DASHBOARD);


        const handleSuccess = (data: any) => {
            localStorage.setItem('ekcy', 'ekcy');
            const customerName = data?.customerName ? cryptoEncryptionDecryption.Encrypt(data?.customerName) : '';
            !customerName.length ? window.sessionStorage.setItem(
                'customerName', customerName
            ) : "";
            window.location.href = data?.url;
        };


        select === "permanentAddress" ? dispatch(UpdateAddressMismatchedPoliciesRequest({ handleDone })) : dispatch(getAddressEkycUrlRequest({ handleSuccess, flag: "addressMismatch" } as any))
    }

    return (<CommonModal
        onClose={() => setOpen(true)}
        open={open}
        modalClassName="px-20 py-20"
        boxProps={{ width: 354 }}
    >

        <Grid container justifyContent={'center'}>
            <Typography className="f-14 fw-600">
                {select === "permanentAddress" ? 'Your address assignment will be forwarded to the insurance company and the permanent address details will be updated on your policy.' : 'Please complete the KYC process and update your eIA address.'}
            </Typography>

            <Grid
                container
                justifyContent="space-around"
                spacing={1}
                className="mt-30"
            >
                <Grid item>
                    <CustomButton
                        text={t('CANCEL')}
                        variant="text"
                        color="primary"
                        startIcon={cancel}
                        showIcon={false}
                        fontWeight={400}
                        fontSize={16}
                        onClick={() => setOpen(false)}
                    />
                </Grid>
                <Grid item>
                    <CustomButton
                        className="f-14"
                        text={t('Proceed')}
                        color="primary"
                        variant="text"
                        fontSize={16}
                        fontWeight={600}
                        onClick={submit}
                        loading={ekycLoading || addressMismatchLoading}
                    />
                </Grid>
            </Grid>

        </Grid>

    </CommonModal>)
}