import { select, call, put, takeEvery } from 'typed-redux-saga';
import { getAnalytics, logEvent } from '@firebase/analytics';
import {
	errorFamilyNameList,
	errorNomineeList,
	fetchFamilyNameListAction,
	fetchProofListAction,
	fetchNomineeList,
	loadFamilyNameList,
	loadNomineeList,
	updateNomineeError,
	updateNomineeRequest,
	updateNomineeSuccess,
	loadProofList,
	errorProofList
} from './Nominee.slice';

import {
	editNomineeData,
	fetchFamiliesNameList,
	fetchNomineeDataList,
	fetchProofmasterList,
} from './Nominee.service';
import { selectIsUserLoggedIn } from '../../Auth/auth.slice';
import { setAlertInfo } from '../Common/Common.slice';
import { fetchUserFamilyListAction } from '../Profile/Profile.slice';

const dest = localStorage.getItem('language');

function* handleFetchNomineeInformation() {
	try {
		const user: any = yield* select(selectIsUserLoggedIn);
		const requestPayload = {
			NomineeList: {
				eiaNo: user.eiaNo,
				policyNo: '',
				companyCode: '',
			},
		};
		const { data }: any = yield* call(fetchNomineeDataList, requestPayload);
		yield* put(loadNomineeList(data));

		if (process.env.REACT_APP_MODE === 'PROD') {
			const analytics = getAnalytics();
			logEvent(analytics, 'Nominee_button_click', {
				button_name: 'Nominee_button',
				Select_Languages: dest,
				Status: 'Success',
				Devices_type: 'Web',
			});
		}
	} catch (e: any) {
		yield* put(errorNomineeList(e.message));

		if (process.env.REACT_APP_MODE === 'PROD') {
			const analytics = getAnalytics();
			logEvent(analytics, 'Nominee_button_click', {
				button_name: 'Nominee_button',
				Select_Languages: dest,
				Status: 'failure',
				Devices_type: 'Web',
			});
		}
	}
}

function* handleEditNomineeInformation({
	payload: { navigate, ...payload },
}: ReturnType<typeof updateNomineeRequest>) {
	try {
		const user: any = yield* select(selectIsUserLoggedIn);
		const requestPayload = {
			UpdateNominee: {
				eiaNo: user.eiaNo,
				policyNo: payload.particularCardData.policyNo,
				companyName: payload.particularCardData.companyName,
				companyCode: payload.particularCardData.companyCode,
				planName: payload.particularCardData.planName,
				lifeAssuredName: payload.particularCardData.lifeAssuredName,
				sumAssured: payload.particularCardData.sumAssured,
				totalPremiumAmount: payload.particularCardData.totalPremiumAmount,
				companyLogo: payload.particularCardData.companyLogo,

				Nominees: [
					...(payload.allFormData.addForm?.map((formData: any) => ({
						action: formData.type,
						nomineeSeqID: formData.nomineeSeqID || '0',
						nomineeFirstName: formData.nomineeFirstName || '',
						nomineeMiddleName: formData.nomineeMiddleName || '',
						nomineeLastName: formData.nomineeLastName || '',
						nomineePercentage: formData.nomineePercentage || '',
						nomineeDOB: `${formData.date}/${formData.month}/${formData.year}`,
						nomineeAge: formData.nomineeAge === 0 ? "0" : formData.nomineeAge || '',
						relationshipCode: formData.relationshipCode || '',
						relationshipName: formData.relationshipName || '',
						address1: formData.address1 || '',
						address2: formData.address2 || '',
						address3: formData.address3 || '',
						city: formData.city || '',
						state: formData.state || '',
						country: '',
						pincode: formData.pincode || '',
						addressSameAsLifeAssured: formData.addressSameAsLifeAssured || 'N',
						// nomineeSentToInsurer:formData.nomineeSentToInsurer || 'N',
						nomineeImageBase64: formData.nomineeImageBase64 || '',
						nomineeImageRequired: 'Y',
						nomineeProofCode: formData?.nomineeProofCode || '',
						oldNomineeFirstName: '',
						oldNomineeMiddleName: '',
						oldNomineeLastName: '',
						oldNomineeDOB: '',
						oldNomineeRelationshipCode: '',
						Appointee: (formData.nomineeAge * 1) < 18 ? formData.Appointee?.map((appointee: any) => ({
							firstName: appointee.firstName || '',
							middleName: appointee.middleName || '',
							lastName: appointee.lastName || '',
							dob:
								`${appointee.date}/${appointee.month}/${appointee.year}` || '',
							gender: appointee.gender || '',
							nomineeRelationship: appointee.relationshipName || '',
							appointeeImageBase64: appointee.appointeeImageBase64 || '',
							appointeeImageName: appointee.appointeeImageName || '',
							address1: appointee.address1 || '',
							address2: appointee.address2 || '',
							address3: appointee.address3 || '',
							city: appointee.city || '',
							state: appointee.state || '',
							country: appointee.country || '',
							pincode: appointee.pincode || '',
							fullAddress: appointee.fullAddress || '',
							addressSameAsNominee: appointee.addressSameAsNominee || '',
							appointeeImageRequired: appointee.appointeeImageRequired || '',
							appointeeProofCode: appointee.appointeeProofCode || ''
							// relationshipName: appointee.relationshipName || '',
						})) : []
					})) || []),
					...(payload.allFormData.changeForm?.map((formData: any) => ({

						action: formData.type,
						nomineeSeqID: formData.nomineeSeqID || '0',
						nomineeFirstName: formData.nomineeFirstName || '',
						nomineeMiddleName: formData.nomineeMiddleName || '',
						nomineeLastName: formData.nomineeLastName || '',
						nomineePercentage: formData.nomineePercentage || '',
						nomineeDOB: `${formData.date}/${formData.month}/${formData.year}`,
						nomineeAge: formData.nomineeAge === 0 ? 0 : formData.nomineeAge || '',
						relationshipCode: formData.relationshipCode || '',
						relationshipName: formData.relationshipName || '',
						address1: formData.address1 || '',
						address2: formData.address2 || '',
						address3: formData.address3 || '',
						city: formData.city || '',
						state: formData.state || '',
						country: '',
						pincode: formData.pincode || '',
						addressSameAsLifeAssured: formData.addressSameAsLifeAssured || 'N',
						// nomineeSentToInsurer:formData.nomineeSentToInsurer || 'N',
						nomineeImageBase64: formData.nomineeImageBase64 || '',
						nomineeImageRequired: 'Y',
						nomineeProofCode: formData?.nomineeProofCode || '',
						oldNomineeFirstName: payload.particularCardData.Nominees.filter((item: any) => item.nomineeSeqID === formData.nomineeSeqID)?.[0].nomineeFirstName,
						oldNomineeMiddleName: payload.particularCardData.Nominees.filter((item: any) => item.nomineeSeqID === formData.nomineeSeqID)?.[0].nomineeMiddleName,
						oldNomineeLastName: payload.particularCardData.Nominees.filter((item: any) => item.nomineeSeqID === formData.nomineeSeqID)?.[0].nomineeLastName,
						oldNomineeDOB: payload.particularCardData.Nominees.filter((item: any) => item.nomineeSeqID === formData.nomineeSeqID)?.[0].nomineeDOB,
						oldNomineeRelationshipCode: payload.particularCardData.Nominees.filter((item: any) => item.nomineeSeqID === formData.nomineeSeqID)?.[0].relationshipCode,
						Appointee: (formData.nomineeAge * 1) < 18 ? formData.Appointee?.map((appointee: any) => ({
							firstName: appointee.firstName || '',
							middleName: appointee.middleName || '',
							lastName: appointee.lastName || '',
							dob:
								`${appointee.date}/${appointee.month}/${appointee.year}` || '',
							gender: appointee.gender || '',
							nomineeRelationship: appointee.relationshipName || '',
							appointeeImageBase64: appointee.appointeeImageBase64 || '',
							appointeeImageName: appointee.appointeeImageName || '',
							address1: appointee.address1 || '',
							address2: appointee.address2 || '',
							address3: appointee.address3 || '',
							city: appointee.city || '',
							state: appointee.state || '',
							country: appointee.country || '',
							pincode: appointee.pincode || '',
							fullAddress: appointee.fullAddress || '',
							addressSameAsNominee: appointee.addressSameAsNominee || '',
							appointeeImageRequired: appointee.appointeeImageRequired || '',
							appointeeProofCode: appointee.appointeeProofCode || ''
							// relationshipName: appointee.relationshipName || '',
						})) : []

					})) || []),
					...(payload.allFormData.removeForm?.map((formData: any) => ({

						action: formData.type,
						nomineeSeqID: formData.nomineeSeqID || '0',
						nomineeFirstName: formData.nomineeFirstName || '',
						nomineeMiddleName: formData.nomineeMiddleName || '',
						nomineeLastName: formData.nomineeLastName || '',
						nomineePercentage: formData.nomineePercentage || '',
						nomineeDOB: `${formData.date}/${formData.month}/${formData.year}`,
						nomineeAge: formData.nomineeAge === 0 ? "0" : formData.nomineeAge || '',
						relationshipCode: formData.relationshipCode || '',
						relationshipName: formData.relationshipName || '',
						address1: formData.address1 || '',
						address2: formData.address2 || '',
						address3: formData.address3 || '',
						city: formData.city || '',
						state: formData.state || '',
						country: '',
						pincode: formData.pincode || '',
						addressSameAsLifeAssured: formData.addressSameAsLifeAssured || 'N',
						// nomineeSentToInsurer:formData.nomineeSentToInsurer || 'N',
						nomineeImageBase64: formData.nomineeImageBase64 || '',
						RemoveNomineeFlag: 'Y',
						nomineeImageRequired: 'Y',
						nomineeProofCode: formData?.nomineeProofCode || '',
						oldNomineeFirstName: payload.particularCardData.Nominees.filter((item: any) => item.nomineeSeqID === formData.nomineeSeqID)?.[0].nomineeFirstName,
						oldNomineeMiddleName: payload.particularCardData.Nominees.filter((item: any) => item.nomineeSeqID === formData.nomineeSeqID)?.[0].nomineeMiddleName,
						oldNomineeLastName: payload.particularCardData.Nominees.filter((item: any) => item.nomineeSeqID === formData.nomineeSeqID)?.[0].nomineeLastName,
						oldNomineeDOB: payload.particularCardData.Nominees.filter((item: any) => item.nomineeSeqID === formData.nomineeSeqID)?.[0].nomineeDOB,
						oldNomineeRelationshipCode: payload.particularCardData.Nominees.filter((item: any) => item.nomineeSeqID === formData.nomineeSeqID)?.[0].relationshipCode,
						Appointee: (formData.nomineeAge * 1) < 18 ? formData.Appointee?.map((appointee: any) => ({
							firstName: appointee.firstName || '',
							middleName: appointee.middleName || '',
							lastName: appointee.lastName || '',
							dob:
								`${appointee.date}/${appointee.month}/${appointee.year}` || '',
							gender: appointee.gender || '',
							nomineeRelationship: appointee.relationshipName || '',
							appointeeImageBase64: appointee.appointeeImageBase64 || '',
							appointeeImageName: appointee.appointeeImageName || '',
							address1: appointee.address1 || '',
							address2: appointee.address2 || '',
							address3: appointee.address3 || '',
							city: appointee.city || '',
							state: appointee.state || '',
							country: appointee.country || '',
							pincode: appointee.pincode || '',
							fullAddress: appointee.fullAddress || '',
							addressSameAsNominee: appointee.addressSameAsNominee || '',
							appointeeImageRequired: appointee.appointeeImageRequired || '',
							appointeeProofCode: appointee.appointeeProofCode || ''
							// relationshipName: appointee.nomineeRelationship || '',
						})) : []

					})) || []),
				],

			},

		};
		const { data }: any = yield* call(editNomineeData, requestPayload);
		yield* put(updateNomineeSuccess(data));
		yield put(
			setAlertInfo({
				open: true,
				type: 'SUCCESS',
				description: data.displayMessage,
			})
		);
		navigate(-1);
		// yield* call(handleFetchNomineeInformation);
		yield* put(fetchUserFamilyListAction());

		if (process.env.REACT_APP_MODE === 'PROD') {
			const analytics = getAnalytics();
			logEvent(analytics, 'Edit_button_click', {
				button_name: 'Edit_button',
				Select_Languages: dest,
				Status: 'Success',
				Devices_type: 'Web',
			});
		}
	} catch (e: any) {
		yield* put(updateNomineeError(e.message));

		if (process.env.REACT_APP_MODE === 'PROD') {
			const analytics = getAnalytics();
			logEvent(analytics, 'Edit_button_click', {
				button_name: 'Edit_button',
				Select_Languages: dest,
				Status: 'failure',
				Devices_type: 'Web',
			});
		}
	}
}

function* handleFetchFamilyListInformation() {
	try {
		const user: any = yield* select(selectIsUserLoggedIn);
		const requestPayload = {
			Customer: {
				eiaNo: user.eiaNo,
			},
		};
		const { data }: any = yield* call(fetchFamiliesNameList, requestPayload);
		const familyList = data.FamilyMembers ? data.FamilyMembers : [];
		yield* put(loadFamilyNameList(familyList));
	} catch (e: any) {
		yield* put(errorFamilyNameList(e.message));
	}
}

function* handleFetchproofList() {
	try {
		const user: any = yield* select(selectIsUserLoggedIn);
		const requestPayload = {
			Customer: {
				eiaNo: user.eiaNo,
			},
		};
		const { data }: any = yield* call(fetchProofmasterList, requestPayload);
		yield* put(loadProofList(data?.proofList || []));
	} catch (e: any) {
		yield* put(errorProofList(e.message));
	}
}

function* watchNominee() {
	yield* takeEvery(fetchNomineeList.type, handleFetchNomineeInformation);
	yield* takeEvery(updateNomineeRequest.type, handleEditNomineeInformation);
	yield* takeEvery(
		fetchFamilyNameListAction.type,
		handleFetchFamilyListInformation
	);
	yield* takeEvery(
		fetchProofListAction.type,
		handleFetchproofList
	);
}

// eslint-disable-next-line import/prefer-default-export
export { watchNominee };
