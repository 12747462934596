import { Grid } from '@mui/material';
import '../Nominee.scss';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import theme from '../../../../common/theme';
import { ReactComponent as checked } from '../../../../common/icons/profile/checked.svg';
import SvgConverter from '../../../../common/SvgConverter';
import { ReactComponent as alert } from '../../../../common/icons/Nominee-orange-alert-icon.svg';
import { ReactComponent as warningRed } from '../../../../common/icons/warningRed.svg.svg';

const NomineeCardList = ({
	nominees,
	overallPercentage,
	isVerified,
	nomineeCount,
}: any) => {
	const { t } = useTranslation();




	return (
		<>
			{isVerified && (
				<Grid item className="nominee-form p-20">
					<Grid container justifyContent="space-between" spacing={1}>
						<Grid item>
							<Grid item container flexWrap="nowrap">
								<Typography
									fontSize={14}
									fontWeight={600}
									color={theme.palette.primary.main}
								>
									{t('NOMINEE')}
								</Typography>
								{nominees?.length !== 0 && (
									<>
										{nominees[0].endoNomineeUpdatedStatus === 'Y' ||
											nominees[0].endoNomineeUpdatedStatus === '' ? (
											<Grid item>
												<Grid container alignItems="center" className="ml-10">
													<Grid item>
														<SvgConverter Icon={checked} />
													</Grid>
													<Grid item>
														<Typography
															className="f-14 ml-2"
															color={theme.palette.success.main}
														>
															{t('UPDATED')}
														</Typography>
													</Grid>
												</Grid>
											</Grid>
										) : (
											<Grid item>
												<Grid container>
													<Grid item>
														<SvgConverter
															Icon={alert}
															className="mt-5 mr-5 ml-10"
														/>
													</Grid>
													<Grid item>
														<Typography
															className="f-14"
															color={theme.palette.secondary.main}
														>
															{t('UNDER_PROCESS')}
														</Typography>
													</Grid>
												</Grid>
											</Grid>
										)}
									</>
								)}
							</Grid>
						</Grid>
						<Grid item className="ml-n30">
							{nominees?.length !== 0 && (
								<Typography
									fontSize={16}
									fontWeight={600}
									color={theme.palette.primary.main}
									className="mb-5 mt-n3"
								>
									{overallPercentage}%
								</Typography>
							)}
						</Grid>
					</Grid>
					{nominees?.map((item: any, index: any) => (
						<Grid
							container
							justifyContent="space-between"
							key={index}
							className=""
						>
							<Grid item>
								<Grid>
									<Typography
										fontSize={18}
										fontWeight={600}
										color={theme.palette.common.black}
										style={{
											maxWidth: '200px',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
										}}
										noWrap
									>
										{item?.nomineeFirstName} {item?.nomineeMiddleName}{' '}
										{item?.nomineeLastName}
									</Typography>
								</Grid>
								{item?.nomineeAge >= 0 &&
									item?.nomineeAge !== '' &&
									item?.nomineeAge !== undefined && (
										<Typography
											fontSize={14}
											color={theme.palette.common.black}
											className=""
										>
											{item?.nomineeAge} {t('YRS')}
										</Typography>
									)}

								{item?.relationshipName?.length === 0 ? (<Grid container
									display="flex"

									alignItems="base">
									<SvgConverter Icon={warningRed} />
									<Typography className="f-14 fw-500 pl-5" color="error">

										{t('RELATIONSHIP_UNKNOWN')}

										{/* {"Relationship Unknown"} */}

									</Typography>

								</Grid>) : (<Typography fontSize={14} color={theme.palette.common.black}>
									{item?.relationshipName}
								</Typography>)}



							</Grid>
							<Grid item className="pr-10">
								<Typography
									fontSize={18}
									fontWeight={600}
									color={theme.palette.common.black}
									className="mb-5"
								>
									{item?.nomineePercentage}
								</Typography>
							</Grid>
						</Grid>
					))}
				</Grid>
			)}
		</>
	);
};

export default NomineeCardList;
