import { Grid, Typography, useTheme } from "@mui/material";
import CommonModal from "../../../../common/ui/Modal/CommonModal";
import { useTranslation } from "react-i18next";
import CustomButton from "../../../../common/ui/CustomButton";
import { useMemo, useState } from "react";
import UploadCard from "../../../../common/ui/UploadCard";
import { useSelector } from "react-redux";
import { selectProofList } from "../Nominee.slice";

const NomineeUploadModal = ({ open, setOpen, submit, IdProof }: any) => {

    const { t } = useTranslation();
    const theme = useTheme();
    const [frontImage, setFrontImage] = useState({ base64: null, fileName: null });
    const [backImage, setBackImage] = useState({ base64: null, fileName: null });
    const [isSubmit, setIsSubmit] = useState(false);

    const restFrom = () => {
        setOpen(false);
        setFrontImage({ base64: null, fileName: null });
        setBackImage({ base64: null, fileName: null });
        setIsSubmit(false);
    }

    const proofList: any = useSelector(selectProofList);

    const proof = useMemo(() => proofList.find((item: any) => item?.nomineeProofCode === IdProof)?.nomineeProofDesc, [IdProof, proofList])

    return (<>
        <CommonModal
            onClose={restFrom}
            open={open}
            modalClassName="nominee-modal px-20 py-20"
            boxProps={{ width: 354 }}
        >
            <Grid className="mb-20">
                <Typography
                    className="f-16 fw-600"
                    color={theme.palette.primary.main}
                >
                    {t('Upload Images - ID Proof')}
                </Typography>
                <Typography
                    className="f-14 fw-400"
                >
                    {proof}
                </Typography>
            </Grid>
            <Grid className="mb-20">
                <Typography
                    className="f-14 fw-600 mb-5"

                >
                    {t('Front')}
                </Typography>
                <UploadCard tapText={'TAP TO UPLOAD IMAGE'} setUpload={setFrontImage} uploadedIMG={frontImage} />
                {frontImage?.base64 == null && isSubmit && <Typography
                    className="f-12 mt-10"
                    color={theme.palette.error.main}
                >
                    Front Image is mandatory
                </Typography>}
            </Grid>

            <Grid>
                <Typography
                    className="f-14 fw-600 mb-5"

                >
                    {t('Back')}
                </Typography>
                <UploadCard tapText={'TAP TO UPLOAD IMAGE'} setUpload={setBackImage} uploadedIMG={backImage} />
                {backImage?.base64 == null && isSubmit && <Typography
                    className="f-12 mt-10"
                    color={theme.palette.error.main}
                >
                    Back Image is mandatory
                </Typography>}
            </Grid>


            <Grid container justifyContent={'space-evenly'}>
                <CustomButton
                    text={t('CANCEL')}
                    variant="text"
                    showIcon={false}
                    // color="inherit"
                    className="f-14 fw-600 mt-20"
                    onClick={() => {
                        // handleImageUploadMore();
                        // setAppointeeUpload('appointee');
                        setOpen(false);
                    }}
                />

                <CustomButton
                    text={t('SUBMIT')}
                    variant="text"
                    // showIcon={false}
                    // color="inherit"
                    className="f-14 fw-600 mt-20"
                    onClick={() => {

                        let frontCanva: HTMLCanvasElement = document.createElement("canvas");
                        let frontContext: CanvasRenderingContext2D = frontCanva.getContext("2d")!;

                        let backCanva: HTMLCanvasElement = document.createElement("canvas");
                        let backContext: CanvasRenderingContext2D = backCanva.getContext("2d")!;

                        let result: HTMLCanvasElement = document.createElement("canvas");
                        let resultContext: CanvasRenderingContext2D = result.getContext("2d")!;


                        var frontImg = new Image();
                        frontImg.onload = function () {
                            frontContext.drawImage(frontImg, 0, 0);
                        };
                        frontImg.src = `data:image/png/jpg/jpeg;base64,${frontImage?.base64}`

                        var backImg = new Image();
                        backImg.onload = function () {
                            backContext.drawImage(backImg, 0, 0);
                        };
                        backImg.src = `data:image/png/jpg/jpeg;base64,${backImage?.base64}`


                        result.width = 300;
                        result.height = 300;
                        resultContext.drawImage(frontImg, 0, 0, 300, 150);
                        resultContext.drawImage(backImg, 0, 150, 300, 150);

                        setIsSubmit(true)

                        if (frontImage?.base64 !== null && backImage?.base64 !== null) {
                            setOpen(false);
                            submit(result.toDataURL().split(',')[1]);
                            restFrom();
                        }

                    }}
                />

            </Grid>
        </CommonModal>
    </>)
}


export default NomineeUploadModal;