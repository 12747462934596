import React, { useEffect, useMemo } from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CustomRadioGroup from '../../../../common/ui/Forms/FormInput/RadioButton/RadioGroup';
import CommonModal from '../../../../common/ui/Modal/CommonModal';
import CustomButton from '../../../../common/ui/CustomButton';
import Separator from '../../../../common/ui/Separator';
import { ReactComponent as AddMobileIcon } from '../../../../common/icons/update-mobile/Add_Mobile.svg';
import { ReactComponent as Cancel } from '../../../../common/icons/update-mobile/cancel_orange.svg';
import { ReactComponent as Verify } from '../../../../common/icons/update-mobile/verify_orange.svg';
import {
	getMobileNoList,
	removeMobileNumber,
	selectPoliciesForMobileNumbers,
} from '../MobileNumber.slice';
import { setAlertInfo } from '../../Common/Common.slice';
import UnverifiedText from '../UnverifiedText';

const RemoveNumberModal = ({
	open,
	setOpen,
	selectedMobileNo,
	toggleAddNumberModal,
	primaryFlag,
	handleInstantVerify
}: any) => {
	const theme = useTheme();
	const [selectedOption, setSelectedOption] = React.useState<any>(false);
	const mobilePolicies = useSelector(selectPoliciesForMobileNumbers);
	const dispatch = useDispatch();
	const { t } = useTranslation();
	useEffect(() => {
		if (!open) setSelectedOption(false);
	}, [open]);

	const mobileNumbers = useMemo(
		() =>
			getMobileNoList({ mobileNo: selectedMobileNo }, mobilePolicies).map(
				(val) => ({
					value: val.mobileNo,
					label: (<Grid container alignItems={'center'}>
						{val.mobileNo} &nbsp;
						{String(val.mobileNoVerified).toUpperCase() !== 'Y' && (
							<UnverifiedText />
						)}
					</Grid>),
					mobileNoVerified: val.mobileNoVerified
				})
			),
		[
			mobilePolicies.mobileBasedPolicies,
			mobilePolicies.unAssignedMobiles,
			selectedMobileNo,
		]
	);
	const handleSubmit = () => {
		if (selectedOption) {
			if (mobileNumbers.filter((item: any) => item.value === selectedOption)?.[0].mobileNoVerified === 'Y') {
				dispatch(
					removeMobileNumber({
						newValue: selectedOption,
						oldValue: selectedMobileNo,
					})
				);
			}
			else {
				handleInstantVerify(selectedOption)
			}
			setOpen(false);
		} else {
			dispatch(
				setAlertInfo({
					open: true,
					type: 'FAILED',
					description: t('PLS_SELECT_A_MOBILE'),
				})
			);
		}
	};

	const handleOptionChange = (value: string) => {
		setSelectedOption(value);
	};

	return (
		<CommonModal
			onClose={() => setOpen(true)}
			open={open}
			modalClassName="px-20 py-20"
			boxProps={{ width: 354 }}
		>
			<Grid item>
				<Grid item>
					<Typography
						className="f-16 fw-600"
						color={theme.palette.primary.main}
					>
						{String(primaryFlag).toUpperCase() === 'Y'
							? t('SELECT_PHONE_NUMBER_PRIMARY')
							: t('REMOVE_THE_PHONE_NUMBER')}
					</Typography>

					<Grid container>
						<Grid item xs={12} className="my-25 custom-scroll-bar" height="40vh">
							<CustomRadioGroup
								name="RadioGroup"
								options={mobileNumbers}
								value={selectedOption}
								onChange={handleOptionChange}
								size="small"
							/>
						</Grid>
						<Grid item xs={12} className="py-2">
							<Typography
								className="f-16 fw-600"
								color={theme.palette.primary.main}
							>
								{t('OR')}
							</Typography>
						</Grid>
					</Grid>
					<Grid item className="py-10">
						<CustomButton
							text={t('ADD_A_MOBILE_NUMBER')}
							variant="text"
							color="primary"
							showIcon={false}
							onClick={() => toggleAddNumberModal(true, false)}
							fontSize={14}
							startIcon={AddMobileIcon}
						/>
					</Grid>

					<Grid item className="py-5">
						<Separator color={theme.palette.primary.main} borderWidth={1} />
					</Grid>
					<Grid container textAlign="center">
						<Grid item xs={12} className="py-10 ">
							<CustomButton
								text={t('PROCEED')}
								variant="text"
								color="primary"
								showIcon={false}
								onClick={handleSubmit}
								fontSize={16}
								fontWeight={600}
								startIcon={Verify}
							/>
						</Grid>
						<Grid item xs={12} className="py-10">
							<CustomButton
								text={t('CANCEL')}
								variant="text"
								color="primary"
								showIcon={false}
								startIcon={Cancel}
								onClick={() => setOpen(false)}
								fontSize={16}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</CommonModal>
	);
};

export default RemoveNumberModal;
