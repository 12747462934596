import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../common/ui/CustomButton';
import NomineeCardHeader from './NomineeComponents/NomineeCardHeader';
import Separator from '../../../common/ui/Separator';
import NomineeCardList from './NomineeComponents/NomineeCardList';
import theme from '../../../common/theme';
import { APP_ROUTES } from '../../../common/constants/Routes';
import { ReactComponent as Edit } from '../../../common/icons/edit.svg';
import { NomineePolicyLock } from '../../../common/utils';
import { ReactComponent as Add } from '../../../common/icons/plus-icon.svg';
import NomineeListModal from './NomineeComponents/NomineeListModal';

interface Props {
	productName: string;
	selfName: string;
	sumAssured: string;
	premium: string;
	companyLogo: string;
	nominees: any;
	cardData: any;
	overallPercentage: any;
	isVerified?: any;
	nomineeCount: any;
	nomineeEndoFlag?: any;
	PermanentAddresses?: any;
}
const NomineeCard = ({
	productName,
	selfName,
	sumAssured,
	premium,
	companyLogo,
	nominees,
	cardData,
	overallPercentage,
	isVerified,
	nomineeCount,
	nomineeEndoFlag,
	PermanentAddresses
}: Props) => {
	const navigate = useNavigate();
	const [submitModalVisible, setsubmitModalVisible] = useState(false);
	const [addModalVisible, setAddModalVisible] = useState(false);

	const handleAddModal = () => {
		setAddModalVisible(true);
	};

	const addNomineeModal = () => {
		setAddModalVisible(false);
		const nomineeData = {
			type: 'ADD',
			nomineeSeqID: '0',
			nomineeSentToInsurer: '',
			nomineeFirstName: '',
			nomineeMiddleName: '',
			nomineeLastName: '',
			nomineePercentage: '',
			nomineeAge: '',
			date: '',
			month: '',
			year: '',
			nomineeDOB: "",
			relationshipName: '',
			relationshipCode: '',
			address1: '',
			address2: '',
			address3: '',
			city: '',
			country: '',
			pincode: '',
			state: '',
			nomineeImageBase64: '',
			PermanentAddress: PermanentAddresses ?? '',
			nomineeProofCode: '',
			Appointee: [
				// {
				// 	firstName: '',
				// 	middleName: '',
				// 	lastName: '',
				// 	dob: '',
				// 	gender: '',
				// 	nomineeRelationship: '',
				// 	appointeeImageBase64: '',
				// 	appointeeImageName: '',
				// 	relationshipName: '',
				// 	address1: '',
				// 	address2: '',
				// 	address3: '',
				// 	city: '',
				// 	state: '',
				// 	country: '',
				// 	pincode: '',
				// 	fullAddress: '',
				// 	addressSameAsNominee: '',
				// 	appointeeImageRequired: 'Y',
				// 	appointeeProofCode: ''
				// },
			],
		};
		handleEditNominee(nomineeData)
	};

	const addNomineeCloseModal = (data: any) => {
		setAddModalVisible(false);
		const [Date, Month, Year] = data.dob.split('-');
		const nomineeData = {
			type: 'ADD',
			nomineeSeqID: '0',
			nomineeSentToInsurer: data.addressVerified,
			nomineeFirstName: data.firstName,
			nomineeMiddleName: data.middleName,
			nomineeLastName: data.lastName,
			nomineePercentage: '',
			nomineeAge: data.nomineeAge,
			date: Date,
			month: Month,
			year: Year,
			nomineeDOB: data.dob,
			relationshipName: data.relationName,
			relationshipCode: data.relationCode,
			address1: data.address1,
			address2: data.address2,
			address3: data.address3,
			city: data.city,
			country: data.country,
			pincode: data.pin,
			state: data.state,
			PermanentAddress: PermanentAddresses ?? '',
			nomineeProofCode: data?.nomineeProofCode ?? '',
			Appointee: []
		};
		handleEditNominee(nomineeData);
	};

	const handleEditNominee = (nomineeData: any) => {
		const card = nomineeData ? { ...cardData, Nominees: [nomineeData || cardData?.Nominees] } : cardData;
		navigate(APP_ROUTES.EDITNOMINEE, { state: { cardData: { ...card, PermanentAddresses } } });
	};

	const { t } = useTranslation();

	return (
		<Grid item>
			<Grid item>
				<Grid container className="nominee-box">
					<Grid item xs={12}>
						<Grid item ml={2}>
							<NomineeCardHeader
								productName={productName}
								selfName={selfName}
								sumAssured={sumAssured}
								premium={premium}
								bankImage={companyLogo}
								cardData={cardData}
							/>
						</Grid>

						<Separator color={theme.palette.primary.main} />
					</Grid>

					{nominees?.length ? <> <Grid item>
						<NomineeCardList
							nominees={nominees}
							overallPercentage={overallPercentage}
							isVerified={isVerified}
							nomineeCount={nomineeCount}
						/>
					</Grid>
						<Separator color={theme.palette.primary.main} borderWidth={1} />
					</> : <></>}
					<Grid item xs={12} className="ml-20 py-10 ">
						{nominees?.length ? <CustomButton
							className="f-16 fw-600"
							text={t('EDIT_NOMINEES')}
							variant="text"
							color="primary"
							showIcon={false}
							startIcon={Edit}
							disabled={NomineePolicyLock(cardData)}
							onClick={() => handleEditNominee(null)}
						/> :
							<CustomButton
								className=" f-14"
								text={t('ADD_A_NOMINEE')}
								variant="text"
								color="primary"
								showIcon={false}
								startIcon={Add}
								disabled={NomineePolicyLock(cardData)}
								onClick={handleAddModal}
							/>}
					</Grid>
				</Grid>
				<NomineeListModal
					open={addModalVisible}
					setOpen={setAddModalVisible}
					addNomineeCloseModal={addNomineeCloseModal}
					addNomineeModal={addNomineeModal}
				/>
			</Grid>
		</Grid>
	);
};

export default NomineeCard;
